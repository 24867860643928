export const LocalstorageService = {
    /**
     * @param key
     * @param value
     * @param ttl in milliseconds
     */
    set(key, value, ttl) {
        LocalstorageTTLService.set(key, value, ttl);
    },

    /**
     * @param key
     * @returns {null|*}
     */
    get(key) {
        return LocalstorageTTLService.get(key);
    }
};
