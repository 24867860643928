import React from 'react';

export const PointsDailCircleSvg = ({
    className = '',
    viewBoxSideSize,
    radius,
    progressPercentage,
    currentStartColor,
    currentEndColor,
    currentCircleWidth,
    nextStartColor,
    nextEndColor,
    nextCircleWidth,
}) => {
    const dashArray = 2 * 3.14 * radius;
    const dashOffset = dashArray * ((100 - progressPercentage) / 100);

    return <svg
        className={`points-dail-progress ${className}`}
        width={viewBoxSideSize}
        height={viewBoxSideSize}
        viewBox={`0 0 ${viewBoxSideSize} ${viewBoxSideSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            className="points-dail-progress__next"
            cx="50%" cy="50%" r={radius}
            stroke="url(#points-dail-progress__stroke_next)"
            strokeWidth={nextCircleWidth}
        />
        <circle
            className="points-dail-progress__current"
            cx="50%" cy="50%" r={radius}
            stroke="url(#points-dail-progress__stroke_current)"
            strokeWidth={currentCircleWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="points-dail-progress__stroke_next"
                x1="50%" y1="0%" x2="50%" y2="100%"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={nextStartColor}/>
                <stop offset="1" stopColor={nextEndColor}/>
            </linearGradient>
            <linearGradient
                id="points-dail-progress__stroke_current"
                x1="50%" y1="0%" x2="50%" y2="100%"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={currentStartColor}/>
                <stop offset="1" stopColor={currentEndColor}/>
            </linearGradient>
        </defs>
    </svg>;
};
