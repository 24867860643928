import React from 'react';
import { StripCtaButton } from '../StripCtaButton';

export const LiveStrip = ({ ctaAction, ctaActionValue, promotionButtonText }) =>
    <div id="promotion-strip" className="promotion-strip--live-mode">
        <div className="promotion-strip--live-row">
            <div className="promotion-strip--live-row-line">
                {[...Array(20)].map((x, i) =>
                    <span key={i}>LIVE</span>
                )}
            </div>
        </div>
        <StripCtaButton
            className="live-button-link"
            ctaAction={ctaAction}
            ctaActionValue={ctaActionValue}
            promotionButtonText={promotionButtonText}
            dataAction="promotion-cta-button"
        />
    </div>;
