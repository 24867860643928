import React from 'react';
import './External1LoyaltyPostLogin.css';
import { useSelector } from 'react-redux';
import { LoyaltyPlansEnum } from '../../enums/loyalty-plans.enum';
import { RequestStatusesEnum } from '../../../../shared/src/enums/request-statuses.enum';
import { External1LoyaltyService } from '../../services/external-1-loyalty.service';
import { NoCountdownModeStrip } from '../../../../shared/src/components/PromotionStrip/NoCountdownModeStrip';
import { PlayerLoyaltyInfoSection } from '../PlayerLoyaltyInfoSection';
import { LocationsSection } from '../LocationsSection';
import { PlayerRewardsSection } from '../PlayerRewardsSection';

export const External1LoyaltyPostLogin = () => {
    const {
        status: apiRequestStatus,
        data: apiData,
    } = useSelector((state) => state.loyalty[LoyaltyPlansEnum.EXTERNAL_1]) || {};

    if (apiRequestStatus !== RequestStatusesEnum.SUCCEDED) {
        return null;
    }

    const {
        topFold,
        stickyStrip,
        locations,
        playerRewards,
    } = External1LoyaltyService.getPostLoginData(apiData);

    return <div className="external-1-loyalty-post-login">
        <NoCountdownModeStrip
            ctaAction={stickyStrip.ctaAction}
            ctaActionValue={stickyStrip.ctaActionValue}
            promotionButtonText={stickyStrip.cta}
            promotionTitle={stickyStrip.title}
            promotionSource="external-loyalty"
        />
        <PlayerLoyaltyInfoSection
            currentLevelNumber={apiData.currentLevelNumber}
            currentLevelPoints={apiData.currentLevelPoints}
            nextLevelPoints={apiData.nextLevelPoints}
            pointsExpirationDate={apiData.pointsExpirationDate}
            cardNumber={apiData.cardNumber}
            registrationDate={apiData.registrationDate}
            message={topFold.message}
        />
        <LocationsSection
            title={locations.title}
            items={locations.items}
        />
        <PlayerRewardsSection
            rewardsData={playerRewards}
        />
    </div>;
};
