import React from 'react';
import './ImageTextItem.css';
import { StringUtilService } from '../../../../shared/src/services/util/string-util.service';

export const ImageTextItem = ({
    imageUrl,
    paragraph,
}) => {
    const paragraphSanitized = StringUtilService.sanitizeHtml(paragraph);

    return <div className="image-text__item">
        <img
            className='image-text__img'
            src={imageUrl}
        />
        <div className="image-text__text" dangerouslySetInnerHTML={{ __html: paragraphSanitized }}></div>
    </div>;
};
