import React from 'react';
import './NotificationMessage.css';

export const NotificationMessage = ({
    message,
}) => {
    return <div className="flying-notification__message">
        {message}
    </div>;
};
