import React from 'react';
import './ParagraphsSection.css';
import { ParagraphsItem } from '../ParagraphsItem';

export const ParagraphsSection = ({
    title = '',
    items,
}) => {
    if (!items) {
        return null;
    }

    return <div className="paragraphs__section">
        {title && <div className="paragraphs_main-title">{title}</div>}

        <div className="paragraphs__items">
            {items.map((item, key) => (
                <ParagraphsItem
                    key={key}
                    title={item.title}
                    paragraph={item.paragraph}
                />
            ))}
        </div>
    </div>;
};
