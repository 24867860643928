export const verticalTypesEnum = Object.freeze({
    CASINO: 'casino',
    SPORT: 'sport',
    BINGO: 'bingo',
    LIVE_CASINO: 'liveCasino',
    SCRATCH_CARDS: 'scratchcards',
    JACKPOT: 'jackpot',
    RECOMMENDATIONS: 'recommendations',
    HOMEPAGE: 'homepage',
});

export const verticalTypeToIdEnum = Object.freeze({
    [verticalTypesEnum.CASINO]: 1,
    [verticalTypesEnum.SPORT]: 2,
    [verticalTypesEnum.BINGO]: 3,
    [verticalTypesEnum.LIVE_CASINO]: 4,
    [verticalTypesEnum.SCRATCH_CARDS]: 5,
});

export const verticalActionTypesEnum = Object.freeze({
    CORE_CATEGORY: 'asgCoreCategory',
    CORE_ACTION: 'asgCoreAction',
    SPORT: 'sport',
    LOAD_GAME: 'loadGame',
    OPEN_LINK: 'openLink',
    MOBILE_APP_CATEGORY: 'mobileAppCategory',
    PLATFORM_CATEGORIES: 'virtualPlatformCategories',
    FEATURED_GAMES: 'featuredGamesCategory',
    BUYWIN_CATEGORY: 'buywinGamesCategory',
    JACKPOT_CATEGORY: 'asgJackpotCategory',
    JACKPOT_VIRTUAL_CATEGORIES: 'virtualJackpotCategories',
    JACKPOT_VIRTUAL_CATEGORY: 'virtualJackpotCategory',
    RECOMMENDATIONS_CATEGORY: 'recommendedGamesCategory',
    FAVORITES_CATEGORY: 'favoriteGamesCategory',
});
