import { useEffect } from 'react';

/**
 * Receives react ref to text element
 * Calculates how many lines text takes
 * and reduces its font-size until it will fit toLinesCount param
 *
 * @param textRef
 * @param toLinesCount
 * @param dependencies
 */
export const useFitTextToRows = (
    textRef,
    toLinesCount,
    dependencies,
) => {
    const fitTextToLines = (el, text, toLines) => {
        // get styles of element
        const styles = getComputedStyle(el);

        // get line height, current font size and height of element
        const lineHeight = parseInt(styles.lineHeight.slice(0, -2));
        const fontSize = parseInt(styles.fontSize.slice(0, -2));
        const elHeight = el.offsetHeight;

        // calculate amount of lines text takes
        const lines = Math.floor(elHeight / lineHeight);

        // if more than toLines - reduce font and repeat until fits
        if (lines > toLines && fontSize > 0) {
            el.style.fontSize = `${fontSize - 1}px`;
            fitTextToLines(el, text, toLines);
        }
    };

    useEffect(() => {
        if (textRef && textRef.current) {
            const el = textRef.current;
            const text = el.innerText;

            // reset prev font sizes
            if (el.style.fontSize !== '') {
                el.style.fontSize = 'inherit';
            }

            fitTextToLines(el, text, toLinesCount);
        }
    }, dependencies);
};
