export const AppActionEnum = {
    REGISTRATION: 'showRegistration',
    DEPOSIT: 'showDeposit',
    WITHDRAW: 'showWithdraw',
    LOGIN: 'showLogin',
    MY_ACCOUNT: 'showMyAccount',
    REALITY_CHECK: 'showRealityCheck',
    CHAT: 'showChat',
    DOCUMENT_UPLOAD: 'showDocumentUpload',
    LOGOUT: 'logout',
    INFO_RESPONSIBLE_POPUP: 'openResponsibleInfoPopup',
    INFO_FAQ_POPUP: 'openFaqInfoPopup',
    GOTO: 'navigateTo',
    SET_IS_LEFT_MENU_OPEN: 'setIsLeftMenuOpen',
    SET_IS_MOBILE_SEARCH_OPEN: 'setIsMobileSearchOpen',
};
