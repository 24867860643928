import {
    bannersActionTypesEnum,
    bannersActionIdsEnum,
} from '../enums/banners.enum';

export const bannersService = {
    linkOrWidgetHandler(
        depositAmount,
        globals,
        type = null,
        actionId = null,
    ) {
        const { AsgApp } = globals;

        switch (type) {
        case bannersActionTypesEnum.WIDGET:
            this.ctaWidgetHandler(actionId, depositAmount, globals);
            break;
        case bannersActionTypesEnum.GAME:
            AsgApp.loadGame(actionId, 'M');
            break;
        case bannersActionTypesEnum.LINK:
        case bannersActionTypesEnum.DEEP_LINK:
            AsgApp.navigateTo(actionId);
            break;
        default:
            break;
        }
    },

    ctaWidgetHandler(actionId, depositAmount, globals) {
        const { AsgFW, AsgApp } = globals;
        const currency = AsgFW.getCurrency();
        switch (actionId) {
        case bannersActionIdsEnum.CHAT:
            AsgFW.showChat();
            break;
        case bannersActionIdsEnum.DEPOSIT:
            AsgFW.isLoggedIn() ?
                AsgFW.showDeposit() :
                AsgFW.showRegistration({ depositAmount, currency });
            break;
        case bannersActionIdsEnum.INFO_PAGE_TERMS:
            AsgApp.openInfoPopup('terms.aspx', new Event('click'));
            break;
        case bannersActionIdsEnum.LOGIN:
            AsgFW.showLogin();
            break;
        case bannersActionIdsEnum.REGISTRATION:
            AsgFW.showRegistration({ depositAmount, currency });
            break;
        }
    },

    addCurrencySymbol(int, currency) {
        return parseInt(int)
            .toLocaleString(
                'en-US',
                { style: 'currency', currency }
            )
            .replace('.00', '');
    },
};
