import { createSlice } from '@reduxjs/toolkit';
import { getRecommendedGames } from './actions';

const initialState = {
    recommendedGames: null,
};

export const recommendationsSlice = createSlice({
    name: 'recommendations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRecommendedGames.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            state.recommendedGames = action.payload;
        });
    },
});
