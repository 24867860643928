import React from 'react';
import './ImageTextSection.css';
import { ImageTextItem } from '../ImageTextItem';

export const ImageTextSection = ({
    title = '',
    items,
}) => {
    if (!items) {
        return null;
    }

    return <div className="image-text__section">
        {title && <div className="image-text__main-title">{title}</div>}

        <div className="image-text__items">
            {items.map((item, key) => (
                <ImageTextItem
                    key={key}
                    imageUrl={item.image}
                    paragraph={item.paragraph}
                />
            ))}
        </div>
    </div>;
};
