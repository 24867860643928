import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GeoComplyService } from '../services/geo-comply.service';

export const useGeoComplyNotificationHook = ({
    setProviderData,
}) => {
    const {
        platformReady,
        config: {
            isNativeApp,
            isNativeSsoApp,
        },
    } = useSelector(state => state.app);
    const isOneOfNativeApps = isNativeApp || isNativeSsoApp;
    const isGeoComplyActive = GeoComplyService.isGeoComplyActive();

    useEffect(() => {
        if (!isOneOfNativeApps && platformReady && isGeoComplyActive) {
            return window.ng_hs.onGeoNotification((key, data) => {
                setProviderData({ key, data });
                Tools.log(`PAM GeoComply notification received - ${key}`, { key, data });
            });
        }
    }, [platformReady]);
};
