import React from 'react';
import './PlayerLoyaltyInfoSection.css';
import { useTranslations } from '../../../../shared/src/hooks/use-translations.hook';
import { GeneralLoyaltyDataService } from '../../services/general-loyalty-data.service';
import { SvgIconMask } from '../../../../shared/src/components/SvgIconMask';
import infoIcon from '../../../images/infoIcon.svg';
import { PointsDailSection } from '../PointsDailSection';

export const PlayerLoyaltyInfoSection = ({
    currentLevelNumber,
    currentLevelPoints,
    nextLevelPoints,
    pointsExpirationDate,
    cardNumber,
    registrationDate,
    message,
}) => {
    const { t } = useTranslations();
    const currentLevelIndex = currentLevelNumber;
    const nextLevelIndex = currentLevelNumber + 1;
    const currentLevelImage = GeneralLoyaltyDataService.getLevelCardImage(currentLevelIndex);
    const currentLevelTitle = GeneralLoyaltyDataService.getLevelName(currentLevelNumber);
    const pointsTerm = GeneralLoyaltyDataService.getPointsTerminology();
    const currentPointsNumber = parseInt(currentLevelPoints);
    const nextLevelData = GeneralLoyaltyDataService.getLevelData(nextLevelIndex);
    const dateLocale = GeneralLoyaltyDataService.getDateLocale();
    const registrationDateString = GeneralLoyaltyDataService.formatDate(registrationDate, dateLocale);
    const baseClass = 'player-loyalty-info';

    const currentLevelWrapperClasses = [
        `${baseClass}__current-level`,
        `${baseClass}-block`,
        `${baseClass}-block_fullwidth--not-desktop`,
        `${baseClass}-block_side-space`,
    ].join(' ');

    const renderInfoMessage = extraClass => <div className={`${baseClass}__message ${extraClass}`}>
        <div className={`${baseClass}__message-icon-wrapper`}>
            <SvgIconMask
                iconUrl={infoIcon}
                width={16}
                height={16}
                bgColor={'var(--text-color)'}
            />
        </div>
        <div className={`${baseClass}__message-text`}>{message}</div>
    </div>;

    return <div className={baseClass}>
        <div className={currentLevelWrapperClasses}>
            <div className={`${baseClass}__current-level-image-wrapper`}>
                <img src={currentLevelImage} alt="" className={`${baseClass}__current-level-image`}/>
            </div>
            <div className={`${baseClass}__current-level-text-wrapper`}>
                <div className={`${baseClass}-block__title`}>{currentLevelTitle}</div>
                <div className={`${baseClass}-block__subtitle ${baseClass}-block__subtitle_closer`}>
                    {t('externalLoyaltyYourBalance')}
                </div>
                <div className={`${baseClass}__current-level-balance`}>
                    <span className={`${baseClass}__current-level-points-num`}>{currentPointsNumber}</span>
                    <span className={`${baseClass}__current-level-points-term`}>{pointsTerm}</span>
                </div>
                {renderInfoMessage(`${baseClass}__visible--only-desktop ${baseClass}__inner-message`)}
            </div>
        </div>
        {renderInfoMessage(`${baseClass}__visible--not-desktop ${baseClass}__outer-message`)}
        <div className={`${baseClass}__card-number ${baseClass}-block ${baseClass}-block_centered`}>
            <div className={`${baseClass}-block__subtitle`}>{t('externalLoyaltyCardNumber')}</div>
            <div className={`${baseClass}-block__data`}>{cardNumber}</div>
        </div>
        <div className={`${baseClass}__registration-date ${baseClass}-block ${baseClass}-block_centered`}>
            <div className={`${baseClass}-block__subtitle`}>{t('externalLoyaltyMemberSince')}</div>
            <div className={`${baseClass}-block__data`}>{registrationDateString}</div>
        </div>
        {nextLevelData && <PointsDailSection
            currentLevelPoints={currentLevelPoints}
            currentLevelIndex={currentLevelIndex}
            nextLevelPoints={nextLevelPoints}
            nextLevelIndex={nextLevelIndex}
            pointsExpirationDate={pointsExpirationDate}
            pointsTerm={pointsTerm}
        />}
        <div className={`${baseClass}__separator`}></div>
    </div>;
};
