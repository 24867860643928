export const NativeEventEmitterEventNamesEnum = {
    OPEN_GAME: 'onOpenGame',
    OPEN_VERTICAL: 'onOpenVertical',
    OPEN_WIDGET: 'onOpenWidget',
    FRESH_CHAT_OPENED: 'onFcOpened',
    FRESH_CHAT_CLOSED: 'onFcClosed',
};

export const NativeEventEmitterWidgetNamesEnum = {
    REGISTRATION: 'registration',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
    CHAT: 'chat',
    CONTACT: 'contact',
    MY_ACCOUNT: 'myAccount',
    DOCUMENT_UPLOAD: 'documentUpload',
    CHANGE_PASSWORD: 'changePassword',
    MY_REWARDS: 'myRewards',
    GAME_HISTORY: 'gameHistory',
    TRANSACTIONS_HISTORY: 'transactionsHistory',
    DEPOSIT_LIMIT: 'depositLimit',
    REALITY_CHECK: 'realityCheck',
    SELF_EXCLUSION: 'SelfExclusion',
};
