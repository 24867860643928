import React from 'react';
import './PointsDailSection.css';
import { useTranslations } from '../../../../shared/src/hooks/use-translations.hook';
import { GeneralLoyaltyDataService } from '../../services/general-loyalty-data.service';
import { PointsDailCircleSvg } from '../PointsDailCircleSvg';

export const PointsDailSection = ({
    currentLevelPoints,
    currentLevelIndex,
    nextLevelPoints,
    nextLevelIndex,
    pointsExpirationDate,
    pointsTerm,
}) => {
    const { t } = useTranslations();
    const nextLevelTitle = GeneralLoyaltyDataService.getLevelName(nextLevelIndex);
    const nextLevelCardImage = GeneralLoyaltyDataService.getLevelCardImage(nextLevelIndex);
    const neededPoints = parseInt(nextLevelPoints - currentLevelPoints);
    const pointsProgressPercentage = currentLevelPoints / nextLevelPoints * 100;
    const dateLocale = GeneralLoyaltyDataService.getDateLocale();
    const pointsExpirationDateString = GeneralLoyaltyDataService.formatDate(pointsExpirationDate, dateLocale);
    let pointsToUnlockText = t('externalLoyaltyPointsToUnlock');
    let earnPointsByDate = t('externalLoyaltyEarnPointsByDate');

    pointsToUnlockText = pointsToUnlockText.replace('[pointsTerm]', pointsTerm);
    earnPointsByDate = earnPointsByDate
        .replace('[neededPoints]', neededPoints)
        .replace('[pointsTerm]', pointsTerm.toLowerCase())
        .replace('[endDate]', pointsExpirationDateString);

    return <div className="loyalty-points-dail">
        <div className="loyalty-points-dail__circle-wrapper">
            <PointsDailCircleSvg
                className="loyalty-points-dail__circle"
                viewBoxSideSize={320}
                radius={136}
                progressPercentage={pointsProgressPercentage}
                currentStartColor={GeneralLoyaltyDataService.getLevelGradientColorStart(currentLevelIndex)}
                currentEndColor={GeneralLoyaltyDataService.getLevelGradientColorEnd(currentLevelIndex)}
                currentCircleWidth={31}
                nextStartColor={GeneralLoyaltyDataService.getLevelGradientColorStart(nextLevelIndex)}
                nextEndColor={GeneralLoyaltyDataService.getLevelGradientColorEnd(nextLevelIndex)}
                nextCircleWidth={24}
            />
            <div className="loyalty-points-dail__circle-data">
                <div className="loyalty-points-dail__needed-points">{neededPoints}</div>
                <div className="loyalty-points-dail__points-description">{pointsToUnlockText}</div>
                <div className="loyalty-points-dail__next-level-title">{nextLevelTitle}</div>
                <img src={nextLevelCardImage} alt="" className="loyalty-points-dail__next-level-card" />
            </div>
        </div>
        <div className="loyalty-points-dail__circle-description">{earnPointsByDate}</div>
    </div>;
};
