import { useState } from 'react';
import { useGeoComplyNotificationHook } from './use-geo-comply-notification.hook';
import { useGeoComplyCheckStatusHook } from './use-geo-comply-check-status.hook';

export const useGeoComplyHook = () => {
    const defaultStatus = true;
    const defaultData = { key: '', data: {} };
    const [isGeoCheckPassed, setIsGeoCheckPassed] = useState(defaultStatus);
    const [geoComplyProviderData, setGeoComplyProviderData] = useState(defaultData);
    const failGeoCheck = () => setIsGeoCheckPassed(false);
    const passGeoCheck = () => setIsGeoCheckPassed(true);
    const resetGeoComplyState = () => {
        setIsGeoCheckPassed(defaultStatus);
        setGeoComplyProviderData(defaultData);
    };

    useGeoComplyNotificationHook({ setProviderData: setGeoComplyProviderData });
    useGeoComplyCheckStatusHook({
        providerData: geoComplyProviderData,
        checkFailed: failGeoCheck,
        checkPassed: passGeoCheck,
    });

    return {
        isGeoCheckPassed,
        geoComplyProviderData,
        resetGeoComplyState,
    };
};
