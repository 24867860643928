import React from 'react';
import { StripCtaButton } from '../StripCtaButton';

export const NoCountdownModeStrip = ({
    promotionTitle,
    promotionButtonText,
    ctaAction,
    ctaActionValue,
    promotionSource
}) => {
    let dataAction = '';

    switch (promotionSource) {
    case 'data-hub':
        dataAction = 'promotion-cta-button';
        break;
    case 'external-loyalty':
        dataAction = 'loyalty-cta-button';
        break;
    }

    return <div id="promotion-strip" className="promotion-strip--noCountdown-mode">
        <div className="promotion-strip--noCountdown-row">
            <span>{promotionTitle}</span>
        </div>
        {promotionButtonText &&
            <StripCtaButton
                className="noCountdown-button"
                ctaAction={ctaAction}
                ctaActionValue={ctaActionValue}
                promotionButtonText={promotionButtonText}
                dataAction={dataAction}
            />}
    </div>;
};
