import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './GeoComplyContainer.css';
import { GeoComplyService } from '../../../services/geo-comply.service';
import { GeoComplyStringsEnum as gcStrings } from '../../../enums/geo-comply-strings.enum';
import { GeoComplyMinimized } from '../GeoComplyMinimized';
import { GeoComplyPopup } from '../GeoComplyPopup';
import { useGeoComplyHook } from '../../../hooks/use-geo-comply.hook';

export const GeoComplyContainer = () => {
    const {
        isGeoCheckPassed,
        geoComplyProviderData,
        resetGeoComplyState,
    } = useGeoComplyHook();
    const [isMinimized, setIsMinimized] = useState(false);
    const providerKey = geoComplyProviderData.key;
    const geoComplyData = geoComplyProviderData.data;
    const providerName = geoComplyData?.[gcStrings.PROVIDER_NAME_KEY];
    const shouldForceMinimized = GeoComplyService.shouldForceMinimizedState(providerKey);
    const isPopupMinimized = isMinimized || shouldForceMinimized;
    const { isLoggedIn } = useSelector((state) => state.player);

    const minimizePopup = () => {
        setIsMinimized(true);
    };
    const maximizePopup = () => {
        setIsMinimized(false);
    };
    const maximizePopupHandler = shouldForceMinimized ? null : maximizePopup;

    useEffect(() => {
        // reset value to show popup
        maximizePopup();
    }, [geoComplyProviderData]);

    useEffect(() => {
        // Reset state on Log out
        if (!isLoggedIn) {
            resetGeoComplyState();
        }
    }, [isLoggedIn]);

    const title = GeoComplyService.getHeader(providerKey, {
        providerName,
    });
    const message = GeoComplyService.getMessage(providerKey, {
        errorMessage: geoComplyData?.[gcStrings.ERROR_MESSAGE_KEY],
        providerName,
    });
    const buttonsData = GeoComplyService.getButtonsParameters(providerKey, {
        downloadUrl: geoComplyData?.[gcStrings.DOWNLOAD_URL_KEY],
        providerName,
        minimizePopup,
        callbackFunctions: {
            downloadCB: geoComplyData?.[gcStrings.CALLBACKS_KEY]?.[gcStrings.DOWNLOAD_CB_KEY],
            // It stops current (or previous) GeoComply check request.
            declineCB: geoComplyData?.[gcStrings.CALLBACKS_KEY]?.[gcStrings.DECLINE_CB_KEY],
        },
    });

    if (isGeoCheckPassed || !isLoggedIn) {
        return null;
    }

    return <div id="geo-comply">
        {isPopupMinimized
            && <GeoComplyMinimized
                title={title}
                maximizePopupHandler={maximizePopupHandler}
            />
            || <GeoComplyPopup
                title={title}
                message={message}
                buttonsData={buttonsData}
            />
        }
    </div>;
};
