import { createAsyncThunk } from '@reduxjs/toolkit';
import { JackpotsService } from '../../services/jackpots.service';
import { verticalActionTypesEnum, verticalTypesEnum } from '../../enums/vertical-types.enum';
import { useAsgActions } from '../../hooks/use-asg-actions.hook';

export const getJackpots = createAsyncThunk(
    'jackpots/fetchJackpots',
    async (requestParams, { getState, dispatch }) => {
        try {
            const { fillVerticals } = useAsgActions();
            const {
                app: {
                    config: { templateDirectoryUri },
                    verticals,
                },
                player: {
                    isLoggedIn,
                },
            } = getState();
            const { data } = await JackpotsService.getJackpots(requestParams);

            const jackpotsFiltered = await JackpotsService.processJackpotsApiResponse({
                verticals,
                verticalTypesEnum,
                verticalActionTypesEnum,
                games: AsgFW.getGamesList().instant,
                jackpots: data.jackpots,
                jackpotsByOrder: data.byOrder,
                jackpotsBanners: data.banners,
                jackpotsCategories: data.categories,
                themePublicPath: templateDirectoryUri,
            });

            if (isStateChangedDuringFetching(getState, isLoggedIn, verticals)) {
                throw new Error('Important changes happened during JackPots fetching.');
            }

            dispatch(fillVerticals({
                byOrder: jackpotsFiltered.verticals,
            }));

            return {
                ...jackpotsFiltered,
                winners: data.winners,
                maturityThresholds: data.maturityThresholds,
            };
        } catch {
            return null;
        }
    }
);

/**
 * Checks is SSO and/or verticals were changed during JP fetching.
 *
 * Allows to prevent overriding just got verticals with old ones.
 * @param {function} getState
 * @param {boolean} initialSso
 * @param {array} initialVerticals
 * @return {boolean}
 */
const isStateChangedDuringFetching = (getState, initialSso, initialVerticals) => {
    const {
        app: {
            verticals: currentVerticals,
        },
        player: {
            isLoggedIn: finalSso,
        },
    } = getState();
    const initialVerticalIds = initialVerticals.map(v => v.id);
    const currentVerticalIds = currentVerticals.map(v => v.id);
    let verticalsIdentical = true;
    for (let i = 0; i < currentVerticalIds.length; i++) {
        if (initialVerticalIds[i] !== currentVerticalIds[i]) {
            verticalsIdentical = false;
        }
    }

    return initialSso !== finalSso || !verticalsIdentical;
};
