import { combineReducers } from 'redux';
// reducers
import { appSlice } from './app/reducer';
import { winnersSlice } from './winners/reducer';
import { playerSlice } from './player/reducer';
import { casinoSlice } from './casino/reducer';
import { promotionsSlice } from './promotions/reducer';
import { jackpotsSlice } from './jackpots/reducer';
import { recommendationsSlice } from './recommendations/reducer';
import { bingoSlice } from './bingo/reducer';
import { sportSlice } from './sport/reducer';
import { favoritesSlice } from './favorites/reducer';
import { loyaltySlice } from './loyalty/reducer';

export const rootReducer = combineReducers({
    app: appSlice.reducer,
    winners: winnersSlice.reducer,
    player: playerSlice.reducer,
    casino: casinoSlice.reducer,
    promotions: promotionsSlice.reducer,
    jackpots: jackpotsSlice.reducer,
    bingo: bingoSlice.reducer,
    sport: sportSlice.reducer,
    recommendations: recommendationsSlice.reducer,
    favorites: favoritesSlice.reducer,
    loyalty: loyaltySlice.reducer,
});
