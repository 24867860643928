export const ArrayUtilService = {
    /**
     * Accepts array, returns "elementsToReturn" amount of random elements
     * @param elements {*[]}
     * @param elementsToReturn {number}
     * @returns {*[]}
     */
    getRandomElements(elements, elementsToReturn = 1) {
        const elementsCount = elements.length;
        const final = [];

        for (let i = 1; i <= elementsToReturn; i++) {
            const randomElement = elements[Math.floor(Math.random() * elementsCount)];
            final.push(randomElement);
        }

        return final;
    }
};
