import { External1LoyaltyContainer } from '../containers/External1LoyaltyContainer';
import { getExternal1LoyaltyData } from '../../../shared/src/store/loyalty/actions';

export const LoyaltyPlansEnum = {
    EXTERNAL_1: 'external_1',
};

export const LoyaltyPlanContainersEnum = {
    [LoyaltyPlansEnum.EXTERNAL_1]: External1LoyaltyContainer,
};

export const LoyaltyPlanApiCallActionsEnum = {
    [LoyaltyPlansEnum.EXTERNAL_1]: getExternal1LoyaltyData,
};
