export const VerticalDetailsService = {
    /**
     * Size of chunk items for SEO strip
     */
    chunkSize: 3,

    /**
     * Cache for success responses
     */
    responsesCache: new Map(),

    /**
     * Do ajax request to WP
     * @param ajaxUri
     * @param language
     * @param verticalAltLocation
     * @param verticalType
     * @returns {Promise<Response>}
     */
    async getVerticalDetailsResponse(ajaxUri, language, verticalAltLocation, verticalType) {
        const params = {
            action: 'getVerticalDetails',
            lang: language,
            altLocation: verticalAltLocation,
            type: verticalType,
        };

        const url = new URL(ajaxUri);

        for (let param in params) {
            url.searchParams.append(param, params[param]);
        }

        const response = await fetch(url);
        const responseJson = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! ${ajaxUri} status: ${response.status} ${response.statusText}`);
        } else if (responseJson.status === 'ok') {
            return responseJson.data;
        } else {
            throw new Error(`API error! ${ajaxUri}`);
        }
    },

    /**
     * Check cache and do request
     * @param ajaxUri
     * @param language
     * @param verticalAltLocation
     * @param verticalType
     * @returns {Promise<unknown>}
     */
    async getCachedVerticalDetailsResponse(ajaxUri, language, verticalAltLocation, verticalType) {
        const key = JSON.stringify({ ajaxUri, language, verticalAltLocation, verticalType });
        if (VerticalDetailsService.responsesCache.has(key)) {
            return VerticalDetailsService.responsesCache.get(key);
        } else {
            const response = await VerticalDetailsService.getVerticalDetailsResponse(
                ajaxUri,
                language,
                verticalAltLocation,
                verticalType,
            );
            VerticalDetailsService.responsesCache.set(key, response);
            return response;
        }
    },

    /**
     * @param inputArray
     * @param chunkSize
     * @returns {*[]}
     */
    chunks(inputArray, chunkSize) {
        const result = [];
        for (let i = 0, length = inputArray.length; i < length; i += chunkSize) {
            result.push(inputArray.slice(i, i + chunkSize));
        }
        return result;
    },
};
