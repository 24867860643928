import React, { useEffect, useRef } from 'react';
import './RollNumber.css';

export const RollNumber = ({
    className = '',
    digit,
    animationDurationSec = 0.5,
    style,
    blockStyle,
    size = '',
    mode = '',
    isAnimationToTop = false,
    onAnimationStateChange,
}) => {
    const numbersRef = useRef(null);
    const numbers = new Array(10).fill(undefined);
    const rollUpNumber = {
        0: '0',
        1: '-1em',
        2: '-2em',
        3: '-3em',
        4: '-4em',
        5: '-5em',
        6: '-6em',
        7: '-7em',
        8: '-8em',
        9: '-9em',
    };

    // reset animation on digit change
    useEffect(() => {
        if (numbersRef && numbersRef.current) {
            numbersRef.current.style.animation = 'none';
            // trigger reflow
            numbersRef.current.offsetHeight;
            numbersRef.current.style.animation = null;
            onAnimationStateChange && onAnimationStateChange(true);
        }
    }, [digit]);

    // trigger a repaint on orientation change
    useEffect(() => {
        if (numbersRef && numbersRef.current) {
            const repaint = () => {
                numbersRef.current.style.display = 'none';
                setTimeout(() => {
                    numbersRef.current.style.display = 'inherit';
                }, 300);
            };

            window.addEventListener('orientationchange', repaint);

            return () => {
                window.removeEventListener('orientationchange', repaint);
            };
        }
    }, [numbersRef]);

    const animationDirection = isAnimationToTop ? 'marginBottom': 'marginTop';

    return (
        <div className={`roll-number ${size} ${mode}`} style={{ ...blockStyle }}>
            <ul
                className={`${className} roll-number__numbers ${mode}`}
                style={{
                    '--roll-to-number': rollUpNumber[digit],
                    [animationDirection]: `calc(${rollUpNumber[digit]} - 1em)`,
                    '--roll-to-number-animation-time': `${animationDurationSec}s`,
                }}
                ref={numbersRef}
            >
                {numbers.map((value, index) => (
                    <div key={index} className={`roll-number__item_container ${size}`}>
                        <li className="roll-number__item" style={{ ...style }}>{index}</li>
                    </div>))}
            </ul>
        </div>
    );
};
