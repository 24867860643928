import React from 'react';
import './LocationsSection.css';
import { LocationsItem } from '../LocationsItem';

export const LocationsSection = ({
    title = '',
    items,
}) => {
    if (!items) {
        return null;
    }

    return <div className="loyalty-locations__section">
        {title && <div className="loyalty-locations__main-title">{title}</div>}

        <div className="loyalty-locations__items">
            {items.map((location, key) => (
                <LocationsItem
                    key={key}
                    name={location.name}
                    link={location.link}
                />
            ))}
        </div>
    </div>;
};
