import React from 'react';
import './LocationsItem.css';
import { SvgIconMask } from '../../../../shared/src/components/SvgIconMask';
import mapPinIcon from '../../../images/mapPinIcon.svg';

export const LocationsItem = ({
    name,
    link = '',
}) => {
    const itemProps = {
        className: 'loyalty-locations__item',
    };

    if (link) {
        itemProps.className += ' loyalty-locations__item--linked';
        itemProps.onClick = () => {window.open(link, '_blank', 'noreferrer');};
    }

    return <div {...itemProps}>
        <SvgIconMask
            iconUrl={mapPinIcon}
            width={18}
            height={30}
        />
        <div className="loyalty-locations__name">{name}</div>
    </div>;
};
