import AndroidNativeAppInterface from './interfaces/android-interface';
import IOSNativeAppInterface from './interfaces/ios-interface';
import DefaultNativeAppInterface from './interfaces/default-interface';

export class NativeEventEmitter {
    constructor(platformType) {
        switch (platformType) {
        case 'android':
            this.eventEmitterInterface = new AndroidNativeAppInterface();
            break;
        case 'ios':
            this.eventEmitterInterface = new IOSNativeAppInterface();
            break;
        default:
            this.eventEmitterInterface = new DefaultNativeAppInterface();
        }
    }

    emit(eventName, eventData = {}) {
        this.eventEmitterInterface.emit(JSON.stringify({
            eventName,
            eventData,
        }));
    }
}
