export const DomService = {
    createElementWithId(id, tagName = 'div') {
        let element= document.getElementById(id);

        if (!element) {
            element = document.createElement(tagName);
            element.id = id;
            document.body.appendChild(element);
        }

        return element;
    },

    removeElementById(id) {
        document.getElementById(id)?.remove();
    },
};
