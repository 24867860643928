export const PamAsgRestapiService = {
    async postRequest(endpoint, data = '', extraHeaders = {}) {
        try {
            const link = this.getApiLink(endpoint);
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...extraHeaders,
                },
                body: data ? JSON.stringify(data) : '',
            };

            const response = await fetch(link, config);
            const responseJson = await response.json();

            if (!response.ok || responseJson.errorCode !== 0) {
                throw new Error(response);
            }

            return responseJson;
        } catch (e) {
            Tools.error('PAM ASG RestAPI/fetch error', e);
            return false;
        }
    },

    getApiLink(endpoint = '') {
        let apiHost = AsgFW.getRbcSettings('pamAsgRestApiHost');

        // Get host from NeoCMS if integration enable and URL exists.
        if (AsgFW.getRbcSettings('neoCmsIntegration')) {
            const pamUrl = AsgFW.getPamUrls(AsgFW.PamInstanceUrlTypesEnum.PAM_ASG_RESTAPI);
            if (pamUrl) {
                apiHost = UrlService.trimSlashes(pamUrl);
            }
        }

        if (!apiHost) {
            throw new Error('No API host data');
        }

        return `${apiHost}/api${endpoint}`;
    },
};
