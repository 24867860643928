import { createAsyncThunk } from '@reduxjs/toolkit';
import { useAsgActions } from '../../hooks/use-asg-actions.hook';
import { LoyaltyPlansEnum } from '../../../../loyalty/src/enums/loyalty-plans.enum';
import { External1LoyaltyService } from '../../../../loyalty/src/services/external-1-loyalty.service';
import { RequestStatusesEnum } from '../../enums/request-statuses.enum';

export const getExternal1LoyaltyData = createAsyncThunk(
    'loyalty/fetchExternal1',
    async (props, { dispatch }) => { // eslint-disable-line no-unused-vars
        const planCode = LoyaltyPlansEnum.EXTERNAL_1;
        const { setLoyaltyWaitingStatus } = useAsgActions();

        dispatch(setLoyaltyWaitingStatus(planCode));
        const data = await External1LoyaltyService.fetchApiData();

        return {
            planCode,
            data,
        };
    },
    {
        condition: (props, { getState }) => { // eslint-disable-line no-unused-vars
            const planCode = LoyaltyPlansEnum.EXTERNAL_1;
            const { loyalty: { [planCode]: { status: fetchStatus } } } = getState();

            if (fetchStatus !== RequestStatusesEnum.DEFAULT) {
                return false;
            }
        }
    }
);
