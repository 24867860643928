import React from 'react';
import { useSelector } from 'react-redux';
import { LoyaltyPlansEnum } from '../../enums/loyalty-plans.enum';
import { RequestStatusesEnum } from '../../../../shared/src/enums/request-statuses.enum';
import { PostLoginError } from '../../components/PostLoginError';
import { External1LoyaltyPostLogin } from '../../components/External1LoyaltyPostLogin';
import { External1LoyaltyPreLogin } from '../../components/External1LoyaltyPreLogin';

export const External1LoyaltyContainer = () => {
    const { isLoggedIn } = useSelector((state) => state.player);
    const { status: apiRequestStatus } = useSelector((state) => state.loyalty[LoyaltyPlansEnum.EXTERNAL_1]) || {};

    const PostLoginContainer = apiRequestStatus === RequestStatusesEnum.FAILED
        ? PostLoginError
        : External1LoyaltyPostLogin;

    return isLoggedIn
        ? <PostLoginContainer />
        : <External1LoyaltyPreLogin />;
};
