import React from 'react';
import { useDateTicker } from '../../../hooks/use-date-ticker.hook';
import { DateBoxWrapper } from '../DateBoxWrapper/DateBoxWrapper';
import { StripCtaButton } from '../StripCtaButton';
import { useTranslations } from '../../../hooks/use-translations.hook';

export const CountdownStrip = ({ countdownTitle, ctaAction, promotionButtonText, countdownUntil, ctaActionValue }) => {
    const { t } = useTranslations();
    const data = useDateTicker(countdownUntil);
    const dateTranslate = ['stripDays', 'stripHr', 'stripMin', 'stripSec'];

    return (<div className="promotion-countdown-strip" id="promotion-strip">
        <div className="countdown-left-part">
            <div className="countdown-strip-component">
                <div className="timer-box-title">
                    {countdownTitle}
                </div>
            </div>

            <div className="time-boxs-wrap">
                {data.map((amount, index) =>
                    <DateBoxWrapper
                        key={index}
                        amount={amount}
                        text={t(dateTranslate[index])} />)}
            </div>
        </div>

        <div className="countdown-strip-content">
            <StripCtaButton
                ctaAction={ctaAction}
                ctaActionValue={ctaActionValue}
                promotionButtonText={promotionButtonText}
                dataAction="promotion-cta-button"
            />
        </div>
    </div>);
};
