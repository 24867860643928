import { GamePageMenuActionsEnum } from '../enums/game-page.enum';

export const EmbeddedGameService = {
    isFeatureEnabledForGame({
        flags: {
            newGamePage,
            newGamePageExcludedVendors,
            isLoggedIn,
        },
        game,
    }) {
        // if new embedded game feature enabled
        if (newGamePage && isLoggedIn) {
            // if excluded list present - show new page only if allowed
            if (newGamePageExcludedVendors !== null && Array.isArray(newGamePageExcludedVendors)) {
                if (
                    !newGamePageExcludedVendors.includes(game.externalGameProvider)
                    && !newGamePageExcludedVendors.includes(game.externalGameSubProvider)
                ) {
                    return true;
                }
            } else {
                // if no excluded config - show for all
                return true;
            }
        }

        return false;
    },

    loadEmbeddedGamePage({
        flags: {
            newGamePage,
            newGamePageExcludedVendors,
            isLoggedIn,
        },
        gamesConfig: {
            gamesById,
            gameId,
        },
        actions: {
            fillCurrentGameLoaded,
            fillCurrentGameId,
        },
        dispatch,
    }) {
        const isEnabled = this.isFeatureEnabledForGame({
            flags: {
                newGamePage,
                newGamePageExcludedVendors,
                isLoggedIn,
            },
            game: gamesById[gameId],
        });

        if (isEnabled) {
            // start game loading
            dispatch(fillCurrentGameLoaded(false));
            dispatch(fillCurrentGameId(gameId));
        }
    },

    getMenuItems({ isMobile }) {
        const menuItems = [];

        if (isMobile) {
            menuItems.push({
                iconName: 'home',
                actionName: GamePageMenuActionsEnum.HOME,
                translationKey: 'home',
            });

            menuItems.push({
                iconName: 'search',
                actionName: GamePageMenuActionsEnum.SEARCH,
                translationKey: 'search',
            });
        }

        return menuItems;
    }
};
