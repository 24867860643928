import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from '../../../hooks/use-translations.hook';
import { DateUtilService } from '../../../services/util/date-util.service';
import { NotificationContainer } from '../NotificationContainer';
import {
    FlyingNotificationTypesEnum,
    FlyingNotificationCtaTypesEnum
} from '../../../enums/flying-notification-types.enum';

export const LastLoginNotification = () => {
    const {
        rbcSettings: {
            shouldShowLastLogin,
        }
    } = useSelector((state) => state.app);
    const { lastLoginTimeStamp } = useSelector(state => state.player);
    const { t } = useTranslations();

    if (!shouldShowLastLogin) {
        return null;
    }

    const lastLoginDateVals = DateUtilService.getZeroLeadingDateTimeParts(lastLoginTimeStamp);

    if (!lastLoginDateVals) {
        return null;
    }

    const dateVal = `${lastLoginDateVals.month}-${lastLoginDateVals.dayOfMonth}-${lastLoginDateVals.year}`;
    const timeVal = `${lastLoginDateVals.hours12format}:${lastLoginDateVals.minutes}:${lastLoginDateVals.seconds}`;

    const props = {
        title: t('lastLogin'),
        // MM-DD-YYYY HH:MM:SS XM
        message: `${dateVal} ${timeVal} ${lastLoginDateVals.dayPeriod}`,
        cta: {
            text: t('gotIt'),
            type: FlyingNotificationCtaTypesEnum.TEXT,
        },
        options: {
            type: FlyingNotificationTypesEnum.ISLAND,
        },
    };

    return <NotificationContainer {...props} />;
};
