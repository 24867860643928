import React from 'react';
import './External1LoyaltyPreLogin.css';
import { External1LoyaltyService } from '../../services/external-1-loyalty.service';
import { NoCountdownModeStrip } from '../../../../shared/src/components/PromotionStrip/NoCountdownModeStrip';
import { ImageTextSection } from '../ImageTextSection';
import { ParagraphsSection } from '../ParagraphsSection';
import { AccordionsSection } from '../../../../shared/src/components/AccordionsSection';

export const External1LoyaltyPreLogin = () => {
    const {
        stickyStrip,
        imageTextSection,
        paragraphs,
        accordions,
    } = External1LoyaltyService.getPreLoginData();

    return <div className="external-1-loyalty-pre-login">
        <NoCountdownModeStrip
            ctaAction={stickyStrip.ctaAction}
            ctaActionValue={stickyStrip.ctaActionValue}
            promotionButtonText={stickyStrip.cta}
            promotionTitle={stickyStrip.title}
            promotionSource="external-loyalty"
        />
        <ImageTextSection
            title={imageTextSection.title}
            items={imageTextSection.items}
        />
        <ParagraphsSection
            title={paragraphs.title}
            items={paragraphs.items}
        />
        <AccordionsSection
            title={accordions.title}
            items={accordions.items}
        />
    </div>;
};
