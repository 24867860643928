import React, { useState } from 'react';
import { PnPDepositWidget } from '../PnPDepositWidget';
import { useSelector } from 'react-redux';
import './BannerSlide.css';
import { bannersService } from '../../../services/banners-service';
import { BannerCtaButton } from '../BannerCtaButton';

export const BannerSlide = ({
    bannerContent  = {} ,
    isAEBFlag = false,
    globals,
    usePnPBanners = true,
}) => {
    const [depositAmount, setDepositAmount] = useState(0);
    const { isMobile } = useSelector((state) => state.app);
    const isTncPresent = !!bannerContent.actions.TnC.type && !!bannerContent.actions.TnC.ActionID;
    const isCtaPresent = (!!bannerContent.actions.CTA.type && !!bannerContent.actions.CTA.ActionID)
        || !!bannerContent.details.PnP;

    const handlePnpDepositAmountClick = (value) => {
        setDepositAmount(parseInt(value));
    };

    const generalClickHandler = () => {
        bannersService.linkOrWidgetHandler(
            depositAmount,
            globals,
            bannerContent.actions.banner.type,
            bannerContent.actions.banner.ActionID,
        );
    };

    const tncClickHandler = () => {
        bannersService.linkOrWidgetHandler(
            depositAmount,
            globals,
            bannerContent.actions.TnC.type,
            bannerContent.actions.TnC.ActionID,
        );
    };

    const ctaClickHandler = () => {
        bannersService.linkOrWidgetHandler(
            depositAmount,
            globals,
            bannerContent.actions.CTA.type,
            bannerContent.actions.CTA.ActionID,
        );
    };

    const interactiveBannerClasses = [
        'interactive-banner',
        isAEBFlag ? 'aspire-engage' : '',
    ];

    const tncClasses = [
        'asg-banners-tnc',
        isMobile ? 'asg-banners-tnc--mobile' : '',
    ];

    const headlinesBlock = (
        <div className="carousel-content__headlines">
            <div className="carousel-content__title">{`${bannerContent.content.title}`}</div>
            <div className="carousel-content__subtitle">{`${bannerContent.content.subtitle}`}</div>
        </div>
    );

    const ctaBlock = (
        <div
            className="carousel-content__cta"
            onClick={(e) => {e.stopPropagation();}}
        >
            {usePnPBanners && bannerContent.details.PnP && <PnPDepositWidget
                className="carousel-content__pnp"
                changeAmountHandler={handlePnpDepositAmountClick}
                depositHandler={ctaClickHandler}
                globals={globals}
            />}

            {(!usePnPBanners || !bannerContent.details.PnP) && isAEBFlag && <BannerCtaButton
                ctaClickHandler={ctaClickHandler}
                ctaText={bannerContent.content.CTAtext}
            />}
        </div>
    );

    return (
        <>
            <div
                className={interactiveBannerClasses.join(' ')}
                onClick={generalClickHandler}
            >
                <div
                    className="interactive-banner__img"
                    style={{ backgroundImage: `url("${bannerContent.content.image}")` }}
                />
                <div className="carousel-content">
                    {isAEBFlag && headlinesBlock}
                    {isCtaPresent && ctaBlock}
                </div>
            </div>

            {isTncPresent && <div
                className={tncClasses.join(' ')}
                onClick={tncClickHandler}
                dangerouslySetInnerHTML={{ __html: bannerContent.content.TnC }}
            ></div>}
        </>
    );
};
