import { createSlice } from '@reduxjs/toolkit';
import { BingoService } from '../../services/bingo.service';

const initialState = {
    roomPlatformIds: null,
    banners: {
        active: false,
        bannersList: null,
    },
    bingoLobbyId: null,
    // room ID that need to be loaded on bingo vertical enter
    bingoRoomIdToLoad: null,
    isSoundOn: true,
    // general bingo loader indicator, room or lobby showed in iframe
    iframeLoaded: false,
    // bingo iframe showing room
    roomLoaded: false,
    // bingo iframe showing bingo lobby
    lobbyLoaded: false,
};

export const bingoSlice = createSlice({
    name: 'bingo',
    initialState,
    reducers: {
        fillBingoRoomIds(state, action) {
            state.roomPlatformIds = action.payload;
        },
        fillBingoLobbyId(state, action) {
            state.bingoLobbyId = action.payload;
        },
        fillShowBingoBanners(state, action) {
            state.banners.active = action.payload;
        },
        fillBingoBanners(state, action) {
            state.banners.bannersList = action.payload;
        },
        loadBingo(state, action) {
            AsgFW.bingo.loadBingo(action.payload);
        },
        fillBingoSoundState(state, action) {
            state.isSoundOn = action.payload;
        },
        openBingoHistory() {
            AsgFW.bingo.openHistory();
        },
        openBingoHelp() {
            AsgFW.bingo.openHelp();
        },
        toggleBingoRoomSound() {
            AsgFW.bingo.toggleBingoRoomSound();
        },
        fillBingoIframeLoaded(state, action) {
            state.iframeLoaded = action.payload;
        },
        fillBingoRoomIdToLoad(state, action) {
            state.bingoRoomIdToLoad = action.payload;
        },
        fillBingoLobbyLoaded(state, action) {
            state.lobbyLoaded = action.payload;
        },
        fillBingoRoomLoaded(state, action) {
            state.roomLoaded = action.payload;
        },
        // resets all state to reload bingo iframe
        bingoBackToLobby(state) {
            BingoService.clearBingoContainer();

            state.iframeLoaded = false;
            state.lobbyLoaded = false;
            state.roomLoaded = false;
        },
    },
});
