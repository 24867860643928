import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoadGameService } from '../../services/load-game.service';

export const loadGameAction = createAsyncThunk(
    'loadGame',
    LoadGameService.loadGameMiddleware
);

export const closeAndLoadGameAction = createAsyncThunk(
    'closeAndLoadGameAction',
    LoadGameService.loadGameMiddleware
);

export const loadEmbeddedGamePage = createAsyncThunk(
    'loadEmbeddedGamePage',
    LoadGameService.loadGameMiddleware
);
