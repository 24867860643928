import { useEffect } from 'react';
import { NotificationKeys } from '../enums/geo-comply-notification-keys.enum';

export const useGeoComplyCheckStatusHook = ({
    providerData,
    checkFailed,
    checkPassed,
}) => {
    useEffect(() => {
        if (!providerData.key) {
            return;
        }

        if (providerData.key !== NotificationKeys.LOCATION_IN_BOUNDS) {
            checkFailed();
        } else {
            checkPassed();
        }
    }, [providerData]);
};
