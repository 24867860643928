import React from 'react';
import { useTranslations } from '../../../hooks/use-translations.hook';

export const ExpiredStrip = () => {
    const { t } = useTranslations();

    return (
        <div id="promotion-strip" className="promotion-strip--end-mode">
            {t('promotionExpired')}
        </div>
    );
};
