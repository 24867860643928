import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list: [],
};

export const winnersSlice = createSlice({
    name: 'winners',
    initialState,
    reducers: {
        setWinners(state, action) {
            state.list = action.payload;
        },
    },
});
