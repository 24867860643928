import { createSlice } from '@reduxjs/toolkit';
import { getPromotionsList } from './actions';

const initialState = {
    promotionsList: null,
};

export const promotionsSlice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPromotionsList.fulfilled, (state, action) => {
            state.promotionsList = action.payload;
            document.dispatchEvent(new Event('asgLtPromotionsReady'));
        });
    },
});
