export const GeoComplyStringsEnum = {
    LOCATION_CHECK_START_REASON: 'manual',
    LOCATION_CHECK_START_MODE: 'F',
    DOWNLOAD_URL_KEY: 'installationLink',
    ERROR_MESSAGE_KEY: 'errorMessage',
    PROVIDER_NAME_KEY: 'providerName',
    CALLBACKS_KEY: 'callbackFunctions',
    DOWNLOAD_CB_KEY: 'downloadSoftware',
    DECLINE_CB_KEY: 'decline',
};
