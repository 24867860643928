import { createSlice } from '@reduxjs/toolkit';
import { closeAndLoadGameAction, loadGameAction } from './actions';

const initialState = {
    prioritizedGames: null,
    gamesById: null,
    buyWin: {
        gameIds: null,
        excludedSubProviders: [],
    },
    currentGame: {
        currentGameId: null,
        gameLoaded: false,
        gameSwitchInProgress: false,
        searchActive: false,
    }
};

export const casinoSlice = createSlice({
    name: 'casino',
    initialState,
    reducers: {
        closeGame() {
            AsgFW.closeGame();
        },
        fillPrioritizedGames(state, action) {
            state.prioritizedGames = action.payload;
        },
        fillBuyWinGameIds(state, action) {
            state.buyWin.gameIds = action.payload;
        },
        fillBuyWinExcludedSubProviders(state, action) {
            state.buyWin.excludedSubProviders = action.payload;
        },
        fillGamesById(state, action) {
            state.gamesById = action.payload;
        },
        fillCurrentGameId(state, action) {
            state.currentGame.currentGameId = parseInt(action.payload, 10);
        },
        fillCurrentGameLoaded(state, action) {
            state.currentGame.gameLoaded = action.payload;
        },
        fillGameSwitchInProgress(state, action) {
            state.currentGame.gameSwitchInProgress = action.payload;
        },
        fillCurrentGameSearchStatus(state, action) {
            state.currentGame.searchActive = action.payload;
        },
        closeEmbeddedGame(state) {
            state.currentGame.currentGameId = null;
            state.currentGame.gameLoaded = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadGameAction.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }

            const { gameId, mode } = action.payload;
            const modeValidated = mode ?? 'M';

            AsgFW.loadGame(gameId, modeValidated);
        });

        builder.addCase(closeAndLoadGameAction.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }

            const { gameId, mode } = action.payload;
            const modeValidated = mode ?? 'M';

            AsgFW.closeAndLoadGame(gameId, modeValidated);
        });
    },
});
