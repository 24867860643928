import { NativeEventEmitterMobileOsTypesEnum as MobileOsTypes } from '../enums/native-event-emitter-mobile-os-types.enum';
import { NativeEventEmitter } from '../../../../js/native-event-emitter/native-event-emitter';
import {
    innerPromotionWidgetActionsEnum as innerPromoWidgets,
    ltPromotionActionCodesEnum
} from '../enums/promotions.enum';
import {
    NativeEventEmitterEventNamesEnum as EventNames,
    NativeEventEmitterWidgetNamesEnum as WidgetNames,
} from '../enums/native-event-emitter-names.enum';
import {
    NativeEventEmitterGameModesEnum as GameModes,
    NativeEventEmitterAccessabilityModesEnum as AccessabilityModes,
} from '../enums/native-event-emitter-modes.enum';
import { verticalTypesEnum } from '../enums/vertical-types.enum';

export const NativeEventEmitterService = {
    emit(action, data = {}, mobileOsType = '') {
        const emitterMobileOsType = Object.values(MobileOsTypes).includes(mobileOsType)
            ? mobileOsType
            : MobileOsTypes.DEFAULT;
        const eventEmitter = new NativeEventEmitter(emitterMobileOsType);

        const eventName = this.getEventName(action);
        const eventData = this.getEventData(action, data);

        eventEmitter.emit(eventName, eventData);
    },

    getEventName(action) {
        let eventName = '';

        const onOpenGameActions = [
            ltPromotionActionCodesEnum.GAME,
        ];
        const onOpenVerticalActions = [
            ltPromotionActionCodesEnum.JACKPOT,
            ltPromotionActionCodesEnum.SPORT_LEAGUE,
            ltPromotionActionCodesEnum.PROVIDER_PAGE,
        ];
        const predefinedEventName = Object.values(EventNames);

        if (onOpenGameActions.includes(action)) {
            eventName = EventNames.OPEN_GAME;
        } else if (onOpenVerticalActions.includes(action)) {
            eventName = EventNames.OPEN_VERTICAL;
        } else if (predefinedEventName.includes(action)) {
            eventName = action;
        } else {
            eventName = EventNames.OPEN_WIDGET;
        }

        return eventName;
    },

    getEventData(action, data) {
        const innerPromoWidgetsList = Object.values(innerPromoWidgets);
        const isLoggedIn = data?.isLoggedIn;
        if (innerPromoWidgetsList.includes(action) && !isLoggedIn) {
            return { widgetName: WidgetNames.REGISTRATION };
        }

        const eventsData = {
            // inner promotion actions
            [innerPromoWidgets.SHOW_DEPOSIT]: { widgetName: WidgetNames.DEPOSIT },
            [innerPromoWidgets.SHOW_WITHDRAW]: { widgetName: WidgetNames.WITHDRAW },
            [innerPromoWidgets.SHOW_CHAT]: { widgetName: WidgetNames.CHAT },
            [innerPromoWidgets.SHOW_CONTACT_US]: { widgetName: WidgetNames.CONTACT },
            [innerPromoWidgets.SHOW_MY_ACCOUNT]: { widgetName: WidgetNames.MY_ACCOUNT },
            [innerPromoWidgets.SHOW_DOCUMENT_UPLOAD]: { widgetName: WidgetNames.DOCUMENT_UPLOAD },
            [innerPromoWidgets.SHOW_CHANGE_PASSWORD]: { widgetName: WidgetNames.CHANGE_PASSWORD },
            [innerPromoWidgets.SHOW_MY_REWARDS]: { widgetName: WidgetNames.MY_REWARDS },
            [innerPromoWidgets.SHOW_GAMES_HISTORY]: { widgetName: WidgetNames.GAME_HISTORY },
            [innerPromoWidgets.SHOW_TRANSACTIONS_HISTORY]: { widgetName: WidgetNames.TRANSACTIONS_HISTORY },
            [innerPromoWidgets.SHOW_DEPOSIT_LIMIT]: { widgetName: WidgetNames.DEPOSIT_LIMIT },
            [innerPromoWidgets.SHOW_REALITY_CHECK]: { widgetName: WidgetNames.REALITY_CHECK },
            [innerPromoWidgets.SHOW_SELF_EXCLUSION]: { widgetName: WidgetNames.SELF_EXCLUSION },
            // Data-hub promotion actions
            [ltPromotionActionCodesEnum.REGISTRATION]: { widgetName: WidgetNames.REGISTRATION },
            [ltPromotionActionCodesEnum.GAME]: {
                gameID: data?.gameId,
                gameMode: isLoggedIn ? GameModes.MONEY : '',
                accessabilityMode: AccessabilityModes.ACCOUNT_BASED,
            },
            [ltPromotionActionCodesEnum.JACKPOT]: { verticalName: verticalTypesEnum.JACKPOT },
            [ltPromotionActionCodesEnum.SPORT_LEAGUE]: { verticalName: verticalTypesEnum.SPORT },
            [ltPromotionActionCodesEnum.PROVIDER_PAGE]: { verticalName: verticalTypesEnum.CASINO },
            [ltPromotionActionCodesEnum.SHOW_DEPOSIT]: { widgetName: WidgetNames.DEPOSIT },
        };

        return eventsData?.[action] ? eventsData[action] : {};
    },
};
