export const foldLoaderVariantsEnum = Object.freeze({
    DOTTED_SIMPLE: 'dotted-simple',
    DOTTED_2ND_LINE_SPLIT: 'dotted-2nd-line-split',
});

export const innerPromotionActionTypesEnum = Object.freeze({
    WIDGET: 'widget',
    LINK: 'link',
    GAME: 'game',
});

export const innerPromotionWidgetActionsEnum = Object.freeze({
    SHOW_DEPOSIT: 'showDeposit',
    SHOW_WITHDRAW: 'showWithdraw',
    SHOW_CHAT: 'showChat',
    SHOW_CONTACT_US: 'showContactUs',
    SHOW_MY_ACCOUNT: 'showMyAccount',
    SHOW_DOCUMENT_UPLOAD: 'showDocumentUpload',
    SHOW_CHANGE_PASSWORD: 'showChangePassword',
    SHOW_MY_REWARDS: 'showMyRewards',
    SHOW_GAMES_HISTORY: 'showGamesHistory',
    SHOW_TRANSACTIONS_HISTORY: 'showTransactionsHistory',
    SHOW_DEPOSIT_LIMIT: 'showDepositLimit',
    SHOW_REALITY_CHECK: 'showRealityCheck',
    SHOW_SELF_EXCLUSION: 'showSelfExclusion',
});

export const ltPromotionStatusesEnum = Object.freeze({
    COUNTDOWN_TILL_LIVE: 'countdown-till-live',
    COUNTDOWN_TILL_END: 'countdown-till-end',
    LIVE: 'live',
    EXPIRED: 'expired',
    ONGOING: 'ongoing',
});

export const ltPromotionActionCodesEnum = Object.freeze({
    REGISTRATION: 'Registration',
    GAME: 'game',
    JACKPOT: 'jackpot',
    SPORT_LEAGUE: 'sport_league',
    PROVIDER_PAGE: 'provider_page',
    SHOW_DEPOSIT: 'show_deposit',
});
