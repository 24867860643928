import { GamePageMenuActionsEnum } from '../enums/game-page.enum';

export const BingoService = {
    bingoWrapId: 'bingo-wrap',

    getMenuItems({
        isMobile,
        isSoundOn,
    }) {
        const menuItems = [];

        if (isMobile) {
            menuItems.push({
                iconName: 'home',
                actionName: GamePageMenuActionsEnum.HOME,
                translationKey: 'home',
            });
        }

        if (isMobile) {
            menuItems.push({
                iconName: 'search',
                actionName: GamePageMenuActionsEnum.SEARCH,
                translationKey: 'search',
            });
        }

        menuItems.push({
            iconName: 'help',
            actionName: GamePageMenuActionsEnum.HELP,
            translationKey: 'help',
        });

        if (isSoundOn) {
            menuItems.push({
                iconName: 'mute',
                actionName: GamePageMenuActionsEnum.SOUND_UPDATE,
                translationKey: 'mute',
            });
        } else {
            menuItems.push({
                iconName: 'sound',
                actionName: GamePageMenuActionsEnum.SOUND_UPDATE,
                translationKey: 'soundOn',
            });
        }

        menuItems.push({
            iconName: 'history',
            actionName: GamePageMenuActionsEnum.HISTORY,
            translationKey: 'history',
        });

        return menuItems;
    },

    /**
     * In case we need to call ng_fw.loadBingo() without page refresh -
     * we need to clean it's content manually
     */
    clearBingoContainer() {
        const el = document.getElementById(this.bingoWrapId);

        if (el) {
            el.innerHTML = '';
        }
    },

    isBingoRoom(game) {
        return game.isBingoRoom;
    },

    isBingoLobby(game) {
        return game.isBingoLobby;
    },
};
