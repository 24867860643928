import React, { useRef } from 'react';
import './AccordionsItem.css';
import { StringUtilService } from '../../services/util/string-util.service';
import { SvgIconMask } from '../SvgIconMask';
import arrowIcon from '../../../images/accordionArrow.svg';

export const AccordionsItem = ({
    title = '',
    paragraph,
    index,
    handleToggle,
    isOpen,
}) => {
    const contentWrapper = useRef();
    const paragraphSanitized = StringUtilService.sanitizeHtml(paragraph);

    const arrowClasses = [
        'accordion-item__arrow',
        isOpen ? 'active' : '',
    ].filter(Boolean).join(' ');

    return <div className="accordions__item accordion-item">
        <div className="accordion-item__header" onClick={() => handleToggle(index)}>
            <div className="accordion-item__title">{title}</div>
            <SvgIconMask
                className={arrowClasses}
                iconUrl={arrowIcon}
                width={14}
                height={8}
                bgColor={'var(--item-title-color, var(--title-color))'}
            />
        </div>
        <div
            ref={contentWrapper}
            className="accordion-item__body"
            style={
                isOpen
                    ? { height: contentWrapper.current.scrollHeight }
                    : { height: '0px' }
            }
        >
            <div
                className="accordion-item__content"
                dangerouslySetInnerHTML={{ __html: paragraphSanitized }}
            ></div>
        </div>
    </div>;
};
