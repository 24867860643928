import React, { useState } from 'react';
import './PlayerRewardsSection.css';
import { PlayerRewardsItem } from '../PlayerRewardsItem';

export const PlayerRewardsSection = ({
    rewardsData
}) => {
    const [activeItemId, setActiveItemId] = useState();
    const rewardKeys = [
        'freeSlotPlay',
        'comps',
        'flexPoints',
    ];

    const handleToggle = key => {
        setActiveItemId(activeItemId !== key ? key : null);
    };

    return <div className="player-rewards__section">
        <div className="player-rewards__items">
            {rewardKeys.map((rewardKey, key) => (
                <PlayerRewardsItem
                    key={key}
                    title={rewardsData[rewardKey].title}
                    subtitle={rewardsData[rewardKey].subtitle}
                    paragraph={rewardsData[rewardKey].paragraph}
                    amount={rewardsData[rewardKey].amount}
                    index={key}
                    handleToggle={handleToggle}
                    isOpen={activeItemId === key}
                />
            ))}
        </div>
    </div>;
};
