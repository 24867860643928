/**
 * @var AsgSharedComponents
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BalanceFold } from './src/components/BalanceFold';
import { store } from './src/store/store';
import { appSlice } from './src/store/app/reducer';
import { winnersSlice } from './src/store/winners/reducer';
import { playerSlice } from './src/store/player/reducer';
import { casinoSlice } from './src/store/casino/reducer';
import { BannersCarousel } from './src/components/BannersCarousel/BannersCarousel';
import { Provider } from 'react-redux';
import { ImgUtilService } from './src/services/util/img-util.service';
import { promotionsSlice } from './src/store/promotions/reducer';
import { jackpotsSlice } from './src/store/jackpots/reducer';
import { bingoSlice } from './src/store/bingo/reducer';
import { sportSlice } from './src/store/sport/reducer';
import { recommendationsSlice } from './src/store/recommendations/reducer';
import { loyaltySlice } from './src/store/loyalty/reducer';
import * as promotionsAsyncActions from './src/store/promotions/actions';
import * as jackpotsAsyncActions from './src/store/jackpots/actions';
import * as recommendationsAsyncActions from './src/store/recommendations/actions';
import * as favoritesAsyncActions from './src/store/favorites/actions';
import * as loyaltyAsyncActions from './src/store/loyalty/actions';
import * as appActions from './src/store/app/actions';
import { initJackpot } from './src/components/JackpotBasic';
import { PromotionStrip } from './src/components/PromotionStrip';
import {
    closeAndLoadGameAction,
    loadEmbeddedGamePage,
    loadGameAction
} from './src/store/casino/actions';
import { DeepLinksService } from './src/services/deep-links.service';
import { EmbeddedGameService } from './src/services/embedded-game.service';
import { NativeEventEmitterService } from './src/services/native-event-emitter.service';
import { NativeEventEmitterEventNamesEnum } from './src/enums/native-event-emitter-names.enum';
import { favoritesSlice } from './src/store/favorites/reducer';
import { GeoComplyService } from './src/services/geo-comply.service';
import { GeoComplyContainer } from './src/components/GeoComply/GeoComplyContainer';
import { DomService } from './src/services/dom.service';
import { FlyingNotificationSlugsEnum } from './src/enums/flying-notification-slugs.enum';
import { LastLoginNotification } from './src/components/FlyingNotification/LastLoginNotification';

/*
  PUBLIC INTERFACE
 */

export const unmountComponent = ReactDOM.unmountComponentAtNode;

export const enums = {
    NativeEventEmitterEventNamesEnum,
};

export const services = {
    ImgUtilService,
    DeepLinksService,
    EmbeddedGameService,
    NativeEventEmitterService,
};

export const renderBalanceFold = (props) => {
    ReactDOM.render(
        <BalanceFold {...props} />,
        props.element
    );
};

export const renderBannersCarousel = (props) => {
    ReactDOM.render(
        <Provider store={store} >
            <BannersCarousel {...props} />
        </Provider>,
        props.element
    );
};

export const renderJackpots = () => initJackpot();

export const renderPromotionStrip = (props) => {
    const element = document.getElementById('promotion-strip-container');

    ReactDOM.render(
        <Provider store={store}>
            <PromotionStrip {...props} />
        </Provider>,
        element
    );
};

export const initGeoComplyPopup = () => {
    if (!GeoComplyService.isGeoComplyActive()) {
        return;
    }

    const portalId = 'geo-comply-portal';
    const portalElement = DomService.createElementWithId(portalId);

    ReactDOM.render(
        <Provider store={store}>
            <GeoComplyContainer />
        </Provider>,
        portalElement
    );
};

export const initFlyingNotification = notificationSlug => {
    const notificationComponents = {
        [FlyingNotificationSlugsEnum.LAST_LOGIN]: LastLoginNotification,
    };
    const doesComponentExist = Object.prototype.hasOwnProperty.call(notificationComponents, notificationSlug);

    if (!doesComponentExist) {
        return null;
    }

    const NotificationComponent = notificationComponents[notificationSlug];

    const containerId = `flying-notification-${notificationSlug}`;
    const containerElement = DomService.createElementWithId(containerId);

    ReactDOM.render(
        <Provider store={store}>
            <NotificationComponent />
        </Provider>,
        containerElement
    );
};

export const terminateFlyingNotification = (notificationSlug) => {
    const containerId = `flying-notification-${notificationSlug}`;
    DomService.removeElementById(containerId);
};

//region Redux
export const state = {
    store,
    actions: {
        ...appSlice.actions,
        ...winnersSlice.actions,
        ...playerSlice.actions,
        ...casinoSlice.actions,
        // replace load game actions used in the code with async ones
        ...{
            loadGame: loadGameAction,
            closeAndLoadGame: closeAndLoadGameAction,
            loadEmbeddedGamePage
        },
        ...promotionsSlice.actions,
        ...jackpotsSlice.actions,
        ...bingoSlice.actions,
        ...sportSlice.actions,
        ...recommendationsSlice.actions,
        ...favoritesSlice.actions,
        ...loyaltySlice.actions,
        async: {
            ...appActions,
            ...promotionsAsyncActions,
            ...jackpotsAsyncActions,
            ...recommendationsAsyncActions,
            ...favoritesAsyncActions,
            ...loyaltyAsyncActions,
        },
    }
};

export const dispatch = (action) => {
    const { dispatch } = store;
    dispatch(action);
};

export const getState = () => (state.store.getState());

export const getActionCreators = () => (state.actions);
export const getDispatch = () => (store.dispatch);
//endregion

/*
  END PUBLIC INTERFACE
 */
