import { useState, useEffect } from 'react';

/**
 * Receive the api date and transform it to the
 * date for RollNumber component
 *
 * @param countdownUntil
 */

export const useDateTicker = (countdownUntil) => {
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [seconds, setSeconds] = useState([]);

    useEffect(() => {
        const calculateData = setInterval(() => {
            const diff = new Date(countdownUntil) - new Date();

            if (diff > 0) {
                let [days, hours, minutes, seconds] = [
                    Math.floor(diff / (1000 * 60 * 60 * 24)),
                    Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
                    Math.floor((diff % (1000 * 60)) / 1000),
                ].map(num => String(num).padStart(2, '0').split(''));

                setDays([...days]);
                setHours([...hours]);
                setMinutes([...minutes]);
                setSeconds([...seconds]);
            }
        }, 1000);

        return () => clearInterval(calculateData);
    }, [countdownUntil]);

    return [
        days,
        hours,
        minutes,
        seconds
    ];
};
