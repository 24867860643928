import { createAsyncThunk } from '@reduxjs/toolkit';
import { FavoritesService } from '../../services/favorites.service';

export const getFavoriteGames  = createAsyncThunk(
    'favorites/games/fetch',
    async (globals) => {
        try {
            const response = await FavoritesService.getPlayerFavoriteGames();
            const games = response?.Games;
            return games.map(game => game.Id);
        } catch (error) {
            globals.Tools.error('favorites/games/fetch error', error);
            return null;
        }
    }
);

export const addFavoriteGame = createAsyncThunk(
    'favorites/games/add',
    async ({ gameId, globals }, { getState }) => {
        try {
            const {
                favorites: {
                    favoriteGames,
                }
            } = getState();
            const newFavoriteGames = [...favoriteGames];

            if (!favoriteGames.includes(gameId)) {
                newFavoriteGames.push(gameId);
            } else {
                throw new Error(`Storage already contains gameId: ${gameId}`);
            }

            await FavoritesService.setPlayerFavoriteGames(newFavoriteGames);

            return newFavoriteGames;
        } catch (error) {
            globals.Tools.error('favorites/games/add error', error);
            return null;
        }
    }
);

export const removeFavoriteGame = createAsyncThunk(
    'favorites/games/remove',
    async ({ gameId, globals }, { getState }) => {
        try {
            const {
                favorites: {
                    favoriteGames,
                }
            } = getState();
            const newFavoriteGames = [...favoriteGames];
            const index = favoriteGames.indexOf(gameId);

            if (index !== -1) {
                newFavoriteGames.splice(index, 1);
            } else {
                throw new Error(`Storage doesn't contain gameId: ${gameId}`);
            }

            await FavoritesService.setPlayerFavoriteGames(newFavoriteGames);

            return newFavoriteGames;
        } catch (error) {
            globals.Tools.error('favorites/games/remove error', error);
            return null;
        }
    }
);
