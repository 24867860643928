export const PamWebapiService = {
    async postRequest (endpoint, data) {
        const link = this.getApiLink(endpoint);
        const response = await fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const responseJson = await response.json();

        if (!response.ok) {
            Tools.error('PAM WebAPI/fetch error', response);
            throw new Error(`HTTP error! ${link} status: ${response.status} ${response.statusText}`);
        } else if (responseJson.ErrorCode === 0) {
            return responseJson;
        } else {
            Tools.error('PAM WebAPI/fetch error', responseJson);
            throw new Error();
        }
    },

    getApiLink (endpoint = '') {
        let apiHost = '';
        // Return URL from NeoCMS if integration enable and URL exists.
        if (AsgFW.getRbcSettings('neoCmsIntegration')) {
            const pamUrl = AsgFW.getPamUrls(AsgFW.PamInstanceUrlTypesEnum.PAM_WEBAPI);
            if (pamUrl) {
                apiHost = UrlService.trimSlashes(pamUrl);
            }
        }

        if (!apiHost) {
            const pamEnv = AsgFW.getPamEnv();
            const env = pamEnv === AsgFW.EnvVars.PROD ? '' : `${pamEnv}.`;
            const domain = AsgFW.getBrandDomain();

            apiHost = `https://webapi.${env}${domain}`;
        }

        return `${apiHost}/api${endpoint}`;
    },
};
