import { createSlice } from '@reduxjs/toolkit';
import { addFavoriteGame, removeFavoriteGame, getFavoriteGames  } from './actions';

const initialState = {
    favoriteGames: [],
};

export const favoritesSlice = createSlice({
    name: 'favorites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        const setFavoritesState = (state, action) => {
            if (!action.payload) {
                return;
            }
            state.favoriteGames = action.payload;
        };

        builder.addCase(getFavoriteGames.fulfilled, setFavoritesState);
        builder.addCase(addFavoriteGame.fulfilled, setFavoritesState);
        builder.addCase(removeFavoriteGame.fulfilled, setFavoritesState);
    },
});
