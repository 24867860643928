import React, { useState } from 'react';
import './AccordionsSection.css';
import { AccordionsItem } from '../AccordionsItem';

export const AccordionsSection = ({
    className = '',
    title = '',
    items,
}) => {
    const [activeItemId, setActiveItemId] = useState();

    if (!items) {
        return null;
    }

    const handleToggle = key => {
        setActiveItemId(activeItemId !== key ? key : null);
    };

    return <div className={`accordions__section ${className}`}>
        {title && <div className="accordions__main-title">{title}</div>}

        <div className="accordions__items">
            {items.map((item, key) => (
                <AccordionsItem
                    key={key}
                    title={item.title}
                    paragraph={item.paragraph}
                    index={key}
                    handleToggle={handleToggle}
                    isOpen={activeItemId === key}
                />
            ))}
        </div>
    </div>;
};
