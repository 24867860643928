import { createSlice } from '@reduxjs/toolkit';
import { getJackpots } from './actions';

const initialState = {
    jackpots: null,
    // array of jackpots IDs by configured in CMS order
    byOrder: null,
    banners: null,
    winners: null,
    maturityThresholds: null,
    gamesToJackpots: null,
    // per each game 1 participant jackpot will be selected randomly
    randomJackpotToGame: null,
    // jackpot IDs sorted by end date
    jackpotsByDateEndAsc: null,
};

export const jackpotsSlice = createSlice({
    name: 'jackpots',
    initialState,
    reducers: {
        setJackpotAmount(state, action) {
            state.jackpots[action.payload.jackpotId].amount[action.payload.currency] = action.payload.amount;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJackpots.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }

            state.jackpots = action.payload.jackpots;
            state.byOrder = action.payload.byOrder;
            state.banners = action.payload.banners;
            state.winners = action.payload.winners;
            state.maturityThresholds = action.payload.maturityThresholds;
            state.gamesToJackpots = action.payload.gamesToJackpots;
            state.randomJackpotToGame = action.payload.randomJackpotToGame;
            state.jackpotsByDateEndAsc = action.payload.jackpotsByDateEndAsc;
        });
    },
});
