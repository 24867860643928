import React from 'react';
import './ParagraphsItem.css';
import { StringUtilService } from '../../../../shared/src/services/util/string-util.service';

export const ParagraphsItem = ({
    title = '',
    paragraph,
}) => {
    const paragraphSanitized = StringUtilService.sanitizeHtml(paragraph);

    return <div className="paragraphs__item">
        {title && <div className="paragraphs__title">{title}</div>}
        <div className="paragraphs__text" dangerouslySetInnerHTML={{ __html: paragraphSanitized }}></div>
    </div>;
};
