export const GeoComplyTranslationKeysEnum = {
    STARTED_TITLE: 'geoComplyStartedTitle',
    GENERAL_ERROR_TITLE: 'geoComplyGeneralErrorTitle',
    GENERAL_ERROR_MESSAGE: 'geoComplyGeneralError',
    SOFTWARE_UNAVAILABLE_TITLE: 'geoComplySoftwareUnavailableTitle',
    SOFTWARE_UNAVAILABLE_MESSAGE: 'geoComplySoftwareUnavailable',
    SOFTWARE_UNAVAILABLE_MESSAGE_GLV: 'geoComplySoftwareUnavailableGLV',
    LOCATION_INVALID_TITLE: 'geoComplyLocationInvalidTitle',
    LOCATION_OUT_OF_BOUNDS_TITLE: 'geoComplyLocationOutOfBoundsTitle',
    SOFTWARE_PERMISSIONS_TITLE: 'geoComplySoftwarePermissionsTitle',
    SOFTWARE_PERMISSIONS_MESSAGE: 'geoComplySoftwarePermissions',
    DOWNLOAD_BUTTON: 'geoComplyCtaDownload',
    TRY_AGAIN_BUTTON: 'geoComplyCtaTryAgain',
    IGNORE_BUTTON: 'geoComplyCtaIgnore',
};
