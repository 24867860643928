import { AppActionEnum } from '../enums/app-action.enum';
import { appSlice } from '../store/app/reducer';

export const AppService = {
    ctaHandler(actionId, actionTarget, dispatch) {
        switch (actionId) {
        case AppActionEnum.INFO_RESPONSIBLE_POPUP:
            dispatch(appSlice.actions.openInfoPopup('responsible.aspx'));
            break;
        case AppActionEnum.INFO_FAQ_POPUP:
            dispatch(appSlice.actions.openInfoPopup('faq.aspx'));
            break;
        case AppActionEnum.GOTO:
            dispatch(appSlice.actions.goTo(actionTarget));
            break;
        case AppActionEnum.REGISTRATION:
            dispatch(appSlice.actions.showRegistration());
            break;
        case AppActionEnum.DEPOSIT:
            dispatch(appSlice.actions.showDeposit());
            break;
        case AppActionEnum.WITHDRAW:
            dispatch(appSlice.actions.showWithdraw());
            break;
        case AppActionEnum.LOGIN:
            dispatch(appSlice.actions.showLogin());
            break;
        case AppActionEnum.MY_ACCOUNT:
            dispatch(appSlice.actions.showMyAccount());
            break;
        case AppActionEnum.REALITY_CHECK:
            dispatch(appSlice.actions.showRealityCheck());
            break;
        case AppActionEnum.CHAT:
            dispatch(appSlice.actions.showChat());
            break;
        case AppActionEnum.DOCUMENT_UPLOAD:
            dispatch(appSlice.actions.showDocumentUpload());
            break;
        case AppActionEnum.LOGOUT:
            dispatch(appSlice.actions.logout());
            break;
        case AppActionEnum.SET_IS_LEFT_MENU_OPEN:
            dispatch(appSlice.actions.setIsLeftMenuOpened(actionTarget));
            break;
        case AppActionEnum.SET_IS_MOBILE_SEARCH_OPEN:
            dispatch(appSlice.actions.setIsMobileSearchOpened(actionTarget));
            break;
        }
    },

    getCurrentVertical(state) {
        if (!state.app.currentVerticalId) {
            return null;
        }

        return state.app.verticalsById[state.app.currentVerticalId];
    },

    getVerticalByType(verticals, requestedVerticalType) {
        return verticals.find((vertical) => (vertical.type === requestedVerticalType));
    },

    getCategoryDataById(verticals, requestedCategoryId) {
        let relatedCategory = null;

        verticals.some(vertical => {
            const { children } = vertical;
            let properChild = null;
            let stopSearch = false;

            if (children?.length > 0) {
                properChild = children.find(child => (child.type === 'category'
                    && child.id === requestedCategoryId
                    && child?.gameIds?.length > 0
                ));
            }

            if (properChild) {
                stopSearch = true;
                relatedCategory = properChild;
            }

            return stopSearch;
        });

        return relatedCategory;
    },

    getGameIdsByCategoryId(verticals, requestedCategoryId) {
        let categoryGameIds = [];

        const category = this.getCategoryDataById(verticals, requestedCategoryId);
        if (category) {
            categoryGameIds = category.gameIds;
        }

        return categoryGameIds;
    },
};
