import { EmbeddedGameService } from './embedded-game.service';

export const DeepLinksService = {
    applyPlatformDeepLinks({
        state,
        dispatch,
        actions,
    }) {
        const queryParams = new URLSearchParams(location.search);
        const deepLinkAction = queryParams.get('neoDl') || queryParams.get('NeoDl') || queryParams.get('NeoDL');

        if (!deepLinkAction) {
            return;
        }

        const actionsMap = {
            'openBingo': this.openBingoAction,
            'game': this.gameAction,
        };

        const deepLinkActionHandler = actionsMap[deepLinkAction];

        if (deepLinkActionHandler) {
            deepLinkActionHandler(queryParams, dispatch, actions, state);
        }
    },

    openBingoAction(queryParams, dispatch, { loadGame }) {
        const roomId = parseInt(queryParams.get('roomId'), 10);

        if (!roomId) {
            return;
        }

        dispatch(loadGame({
            gameId: roomId,
        }));
    },

    gameAction(queryParams, dispatch, actions, state) {
        const gameId = parseInt(queryParams.get('GID') || queryParams.get('gid'), 10);

        if (!gameId) {
            return;
        }

        const {
            config: {
                newGamePage,
                newGamePageExcludedVendors,
                newGamePageExcludedVendorsMobile,
            },
            isMobile,
        } = state.app;

        const {
            gamesById,
        } = state.casino;

        const {
            isLoggedIn,
        } = state.player;

        const {
            fillCurrentGameLoaded,
            fillCurrentGameId,
            fillGameSwitchInProgress,
        } = actions;



        const detectedExcludedVendors = isMobile ? newGamePageExcludedVendorsMobile : newGamePageExcludedVendors;

        EmbeddedGameService.loadEmbeddedGamePage({
            flags: {
                newGamePage,
                newGamePageExcludedVendors: detectedExcludedVendors,
                isLoggedIn,
            },
            gamesConfig: {
                gamesById,
                gameId,
            },
            actions: {
                fillCurrentGameLoaded,
                fillCurrentGameId,
                fillGameSwitchInProgress,
            },
            dispatch,
        });
    },
};
