import React from 'react';
import './GeoComplyButton.css';

export const GeoComplyButton = ({
    buttonClassModifiers,
    onClickHandler,
    buttonText,
}) => {
    const baseClass = 'geo-comply__cta-btn';
    let buttonClass = baseClass;

    if (buttonClassModifiers.length > 0) {
        const buttonExtraClasses = buttonClassModifiers
            .map(m => `${baseClass}--${m}`)
            .join(' ');
        buttonClass += ` ${buttonExtraClasses}`;
    }

    return <div
        className={buttonClass}
        onClick={onClickHandler}
    >
        {buttonText}
    </div>;
};
