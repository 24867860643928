import React from 'react';
import './BalanceFold.css';

export const BalanceFold = ({
    amounts = null,
    buttons = null,
    className = '',
}) => {
    if (!amounts) {
        return null;
    }

    return (
        <div
            className={`${className} asg-balance-fold`}
        >
            <div className="asg-balance-fold__top">
                <div className="asg-balance-fold__amounts">
                    {amounts.map((amount, index) => {
                        return (
                            <div
                                className={`asg-balance-fold__amount ${amount.main
                                    ? 'asg-balance-fold__amount--main' : ''}`}
                                key={index}
                            >
                                <div className="asg-balance-fold__title">{amount.title}</div>
                                <div className="asg-balance-fold__number">{amount.number}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="asg-balance-fold__icon-wrap">
                    <div className="asg-balance-fold__icon" />
                </div>
            </div>
            {buttons && <div className="asg-balance-fold__buttons">
                {buttons.map((button, index) => {
                    return (
                        <div
                            className="asg-balance-fold__button"
                            key={index}
                            onClick={button.handler}
                        >{button.text}</div>
                    );
                })}
            </div>}
        </div>
    );
};
