import { sanitize } from 'dompurify';

export const StringUtilService = {
    sanitizeHtml(html) {
        return sanitize(html);
    },

    padWithZero(str) {
        return String(str).padStart(2, '0');
    },

    /**
     * Decode HTML entity to symbol. For example &pound; to £
     * @param html
     * @return {string}
     */
    decodeHtmlEntity(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    },

    /**
     * Capitalize first letter of a word. For example capitalize to Capitalize
     * @param string
     * @return {string}
     */
    capitalize (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
};
