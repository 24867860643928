import React from 'react';

export const StripCtaButton = ({ ctaAction, ctaActionValue, promotionButtonText, className, dataAction = '' }) =>
    <button
        className={`promotion-strip--button playnow-button-link ${className}`}
        data-name={dataAction}
        data-btn-action={ctaAction}
        data-btn-action-value={ctaActionValue}>
        {promotionButtonText}
    </button>;
