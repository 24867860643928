import React, { useRef } from 'react';
import './PlayerRewardsItem.css';
import { StringUtilService } from '../../../../shared/src/services/util/string-util.service';
import { SvgIconMask } from '../../../../shared/src/components/SvgIconMask';
import arrowIcon from '../../../../shared/images/accordionArrow.svg';

export const PlayerRewardsItem = ({
    title = '',
    subtitle = '',
    paragraph = '',
    amount,
    index,
    handleToggle,
    isOpen,
}) => {
    const contentWrapper = useRef();
    const paragraphSanitized = StringUtilService.sanitizeHtml(paragraph);

    if (!title && !subtitle && !paragraph) {
        return null;
    }

    const arrowClasses = [
        'player-reward__arrow',
        isOpen ? 'active' : '',
    ].filter(Boolean).join(' ');

    return <div className="player-rewards__item player-reward">
        <div
            className="player-reward__accordion-header player-reward-header"
            onClick={() => handleToggle(index)}
        >
            <div className="player-reward-header__left-col">
                {title && <div className="player-reward__title">{title}</div>}
                {subtitle && <div className="player-reward__subtitle">{subtitle}</div>}
                {paragraphSanitized && <SvgIconMask
                    className={arrowClasses}
                    iconUrl={arrowIcon}
                    width={14}
                    height={8}
                    bgColor={'var(--title-color)'}
                />}
            </div>
            <div className="player-reward-header__right-col">
                <div className="player-reward__amount">{amount}</div>
            </div>
        </div>
        {paragraphSanitized && <div
            ref={contentWrapper}
            className="player-reward__accordion-body"
            style={
                isOpen
                    ? { height: contentWrapper.current.scrollHeight }
                    : { height: '0px' }
            }
        >
            <div className="player-reward__content" dangerouslySetInnerHTML={{ __html: paragraphSanitized }}></div>
        </div>}
    </div>;
};
