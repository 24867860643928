import React from 'react';
import ReactDOM from 'react-dom';
import JackpotBasic from './container/JackpotBasic';
import { Provider } from 'react-redux';
import { dispatch } from '../../../index';

export const initJackpot = () => {
    const elements = document.getElementsByClassName('casino-thumbnail__jackpot');
    const { async: { getJackpots } } = AsgSharedComponents.state.actions;
    const casinoId = AsgFW.getBrandID();
    const country = AsgFW.getCurrentCountry();
    const currency = AsgFW.getCurrency();
    const currencySign = AsgFW.getCurrencySignByCode(currency);
    const regulation = AsgFW.getRegulation();
    dispatch(getJackpots({ casinoId, country, regulation, currencyCode: currency }));

    for (let elem of elements) {
        const id = elem?.dataset?.gameId;
        renderJackpotBasic(elem, id, currency, currencySign);
    }
};

const renderJackpotBasic = (element, id, currency, currencySign) => {
    const { store } = AsgSharedComponents.state;
    ReactDOM.render(
        <Provider store={store}>
            <JackpotBasic id={id} currency={currency} currencySign={currencySign} />
        </Provider>,
        element
    );
};
