import React from 'react';
import { RollNumber } from '../../RollNumber';

export const DateBoxWrapper = ({ amount, text }) =>
    <div className={`${amount} time-box-wrap`}>
        <div id={`countdown-strip-${amount}`} className={`${amount} time-box countdown-strip-${amount}`}>
            {amount.map((item, index) =>
                <RollNumber key={index} digit={item} className="roll-amount"/>)}
        </div>
        <div className={`text ${amount}`}>{text}</div>
    </div>;
