import { createAsyncThunk } from '@reduxjs/toolkit';
import { RecommendationsService } from '../../services/recommendations.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { FavoritesService } from '../../services/favorites.service';

export const getRecommendedGames = createAsyncThunk(
    'recommendations/games/fetch',
    async (props) => {
        try {
            const requestParams = props.requestParams;
            const { userId, regulation, country, casinoId, platformId } = requestParams;
            const cacheKey = `_u${userId}_r${regulation}_c${country}_b${casinoId}_${platformId}`;
            const mostPlayedGamesKey = `recommendedMostPlayedGames${cacheKey}`;
            const gamesKey = `recommendedGames${cacheKey}`;
            const day = 24 * 3600 * 1000;
            let gameWeight = 1;
            let mostPlayedGamesData = LocalstorageService.get(mostPlayedGamesKey);
            let gamesData = LocalstorageService.get(gamesKey);

            if (mostPlayedGamesData === null) {
                const mostPlayedResponse = await RecommendationsService.getRecommendedMostPlayedGames(requestParams);
                mostPlayedGamesData = mostPlayedResponse?.data?.recommendations || null;
                if (mostPlayedGamesData !== null) {
                    LocalstorageService.set(mostPlayedGamesKey, mostPlayedGamesData, day);
                }
            }

            if (mostPlayedGamesData && Array.isArray(mostPlayedGamesData) && mostPlayedGamesData.length) {
                requestParams.gameIds = {};
                mostPlayedGamesData.forEach(gameId => {
                    // every next game has weight that will be plus 1
                    requestParams.gameIds[gameId] = gameWeight;
                    gameWeight++;
                });
            }

            if (gamesData === null) {
                const gamesResponse = await RecommendationsService.getRecommendedGames(requestParams);
                gamesData = gamesResponse?.data?.recommendations || null;
                if (gamesData !== null) {
                    LocalstorageService.set(gamesKey, gamesData, day);
                }
            }
            if (gamesData && mostPlayedGamesData?.[0]) {
                gamesData.forEach(item => {
                    item.category.gameId = mostPlayedGamesData[0];
                });
            }

            if (props.categoriesOrder) {
                // Add favorite games category
                FavoritesService.addFavoritesCategory(gamesData, props.categoriesOrder);

                // Filter categories by array of categories names in vertical actionData
                gamesData = FavoritesService.getFilteredCategories(gamesData, props.categoriesOrder);

                // Sort categories by array of categories names in vertical actionData
                FavoritesService.sortCategories(gamesData, props.categoriesOrder);
            }

            return gamesData;
        } catch (error) {
            props.globals.Tools.error('recommendations/games/fetch error', error);
            return null;
        }
    }
);
