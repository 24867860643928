import { BingoService } from './bingo.service';
import { AppService } from './app.service';
import { verticalTypesEnum } from '../enums/vertical-types.enum';
import { useAsgActions } from '../hooks/use-asg-actions.hook';
import { EmbeddedGameService } from './embedded-game.service';

/**
 * Handles app state during load game process
 */
export const LoadGameService = {
    /**
     * Applies relevant actions before game started to load on platform side
     */
    async loadGameMiddleware(gameLoadPayload, { getState, dispatch }) {
        const { gameId } = gameLoadPayload;
        const state = getState();
        const { gamesById } = state.casino;

        const loadedGame = gamesById[gameId];

        if (!loadedGame) {
            throw new Error(`LoadGameService.loadGameMiddleware could not find game id = ${gameId}`);
        }

        const middleware = LoadGameService.getMiddlewareByGameType(loadedGame);

        return middleware(gameLoadPayload, state, dispatch);
    },

    /**
     * Depending on game type - applies relevant middleware for game loading process
     */
    getMiddlewareByGameType(game) {
        if (BingoService.isBingoLobby(game)) {
            return LoadGameService.loadBingoLobbyMiddleware;
        }

        if (BingoService.isBingoRoom(game)) {
            return LoadGameService.loadBingoRoomMiddleware;
        }

        return LoadGameService.loadCasinoGameMiddleware;
    },

    /**
     * Handles app state during bingo lobby loading
     */
    loadBingoLobbyMiddleware(gameLoadPayload, state, dispatch) {
        const { verticals } = state.app;
        const { setCurrentVertical, bingoBackToLobby, closeEmbeddedGame } = useAsgActions();
        const currentVertical = AppService.getCurrentVertical(state);
        const isOnBingoVertical = currentVertical && currentVertical.type === verticalTypesEnum.BINGO;

        // close game page during bingo loading
        dispatch(closeEmbeddedGame());

        // if on bingo vertical - call back to lobby
        if (isOnBingoVertical) {
            dispatch(bingoBackToLobby());
        }

        // if not on bingo vertical - just open bingo vertical
        if (!isOnBingoVertical) {
            // switch to Bingo vertical
            const bingoVertical = AppService.getVerticalByType(verticals, verticalTypesEnum.BINGO);
            dispatch(setCurrentVertical(String(bingoVertical.id)));
        }

        // cancel regular load game process with AsgFW
        return false;
    },

    /**
     * Handles app state during bingo room loading
     */
    loadBingoRoomMiddleware(gameLoadPayload, state, dispatch) {
        const { gameId: bingoRoomId } = gameLoadPayload;
        const { verticals } = state.app;
        const { setCurrentVertical, bingoBackToLobby, fillBingoRoomIdToLoad, closeEmbeddedGame } = useAsgActions();
        const currentVertical = AppService.getCurrentVertical(state);
        const isOnBingoVertical = currentVertical && currentVertical.type === verticalTypesEnum.BINGO;

        // close game page during bingo loading
        dispatch(closeEmbeddedGame());

        // if current vertical not bingo - set bingo vertical and room ID to load
        if (!isOnBingoVertical) {
            // reset bingo iframe status
            dispatch(bingoBackToLobby());

            // set room id to load
            dispatch(fillBingoRoomIdToLoad(bingoRoomId));

            // switch to Bingo vertical
            const bingoVertical = AppService.getVerticalByType(verticals, verticalTypesEnum.BINGO);
            dispatch(setCurrentVertical(String(bingoVertical.id)));
        }

        // if current vertical is bingo - set room ID to load
        if (isOnBingoVertical) {
            // set room id to load
            dispatch(fillBingoRoomIdToLoad(bingoRoomId));

            // reset bingo iframe status
            dispatch(bingoBackToLobby());
        }

        // cancel regular load game process with AsgFW
        return false;
    },

    /**
     * Handles app state during casino game loading
     */
    loadCasinoGameMiddleware(gameLoadPayload, state, dispatch) {
        const { gameId } = gameLoadPayload;
        const { verticals } = state.app;
        const { currentGame: { currentGameId } } = state.casino;
        const {
            setCurrentVertical,
            bingoBackToLobby,
            fillCurrentGameId,
            fillCurrentGameLoaded,
        } = useAsgActions();
        const currentVertical = AppService.getCurrentVertical(state);
        const isOnBingoVertical = currentVertical && currentVertical.type === verticalTypesEnum.BINGO;

        // ignore opening same game id
        if (currentGameId === gameId) {
            return false;
        }

        // if current vertical is bingo - switch to Casino
        if (isOnBingoVertical) {
            // switch to Casino vertical
            const casinoVertical = AppService.getVerticalByType(verticals, verticalTypesEnum.CASINO);
            dispatch(setCurrentVertical(String(casinoVertical.id)));

            // reset bingo iframe status
            dispatch(bingoBackToLobby());
        }

        // new embedded game config
        const {
            newGamePage,
            newGamePageExcludedVendors,
            newGamePageExcludedVendorsMobile,
        } = state.app.config;
        const { isMobile } = state.app;
        const { gamesById } = state.casino;
        const { isLoggedIn } = state.player;

        const detectedExcludedVendors = isMobile ? newGamePageExcludedVendorsMobile : newGamePageExcludedVendors;

        EmbeddedGameService.loadEmbeddedGamePage({
            flags: {
                newGamePage,
                newGamePageExcludedVendors: detectedExcludedVendors,
                isLoggedIn,
            },
            gamesConfig: {
                gamesById,
                gameId,
            },
            actions: {
                fillCurrentGameLoaded,
                fillCurrentGameId,
            },
            dispatch,
        });

        // proceed with regular load game process with AsgFW
        return gameLoadPayload;
    },
};
