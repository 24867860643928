import { useTranslations } from '../hooks/use-translations.hook';
import { useSelector } from 'react-redux';
import { NotificationKeys } from '../enums/geo-comply-notification-keys.enum';
import { GeoComplyTranslationKeysEnum as TranslationKeys } from '../enums/geo-comply-translation-keys.enum';
import { GeoComplyProvidersEnum } from '../enums/geo-comply-providers.enum';
import { GeoComplyButtonModificatorsEnum } from '../enums/geo-comply-button-modificators.enum';
import { GeoComplyStringsEnum } from '../enums/geo-comply-strings.enum';

export const GeoComplyService = {
    // region General

    isGeoComplyActive() {
        return AsgFW.getRbcSettings('geoComplyActive') || false;
    },

    shouldForceMinimizedState(providerKey) {
        return providerKey === NotificationKeys.CHECK_STARTED;
    },

    /**
     * @returns {string|null}
     */
    getHeader(providerKey, extraData) {
        const { t } = useTranslations();
        const { isMobile } = useSelector(state => state.app);
        const { providerName = '' } = extraData;

        const headerTranslationKeysPerProviderKey = {
            [NotificationKeys.CHECK_STARTED]: TranslationKeys.STARTED_TITLE,
            [NotificationKeys.GENERAL_ERROR]: TranslationKeys.GENERAL_ERROR_TITLE,
            [NotificationKeys.SOFTWARE_UNAVAILABLE]:
                this.getSoftwareUnavailableTitleTranslationKey(isMobile, providerName),
            [NotificationKeys.LOCATION_INVALID]: TranslationKeys.LOCATION_INVALID_TITLE,
            [NotificationKeys.LOCATION_OUT_OF_BOUNDS]: TranslationKeys.LOCATION_OUT_OF_BOUNDS_TITLE,
            [NotificationKeys.SOFTWARE_PERMISSIONS]: TranslationKeys.SOFTWARE_PERMISSIONS_TITLE,
        };

        return providerKey in headerTranslationKeysPerProviderKey
            ? t(headerTranslationKeysPerProviderKey[providerKey])
            : null;
    },

    getSoftwareUnavailableTitleTranslationKey(isMobile, providerName) {
        let translationKey = TranslationKeys.SOFTWARE_UNAVAILABLE_TITLE;

        if (isMobile && providerName !== GeoComplyProvidersEnum.GLV) {
            translationKey = TranslationKeys.GENERAL_ERROR_TITLE;
        }

        return translationKey;
    },

    // endregion
    // region Messages

    /**
     * @returns {string|null}
     */
    getMessage(providerKey, extraData) {
        const { t } = useTranslations();
        const { isMobile } = useSelector(state => state.app);
        const { providerName = '' } = extraData;

        const messageByTranslationKeys = {
            [NotificationKeys.GENERAL_ERROR]: TranslationKeys.GENERAL_ERROR_MESSAGE,
            [NotificationKeys.SOFTWARE_UNAVAILABLE]:
                this.getSoftwareUnavailableMessageTranslationKey(isMobile, providerName),
            [NotificationKeys.SOFTWARE_PERMISSIONS]: TranslationKeys.SOFTWARE_PERMISSIONS_MESSAGE,
        };
        const messageByProvider = [
            NotificationKeys.LOCATION_INVALID,
            NotificationKeys.LOCATION_OUT_OF_BOUNDS,
        ];

        let message = null;

        if (providerKey in messageByTranslationKeys) {
            const translationKey = messageByTranslationKeys[providerKey];
            message = t(translationKey);
        } else if (messageByProvider.includes(providerKey)) {
            message = extraData?.errorMessage.join(' ');
        }

        return message;
    },

    getSoftwareUnavailableMessageTranslationKey(isMobile, providerName) {
        let translationKey = TranslationKeys.SOFTWARE_UNAVAILABLE_MESSAGE;

        if (isMobile) {
            translationKey = providerName === GeoComplyProvidersEnum.GLV
                ? TranslationKeys.SOFTWARE_UNAVAILABLE_MESSAGE_GLV
                : TranslationKeys.GENERAL_ERROR_MESSAGE;
        }

        return translationKey;
    },

    // endregion
    // region Buttons

    getButtonsParameters(providerKey, extraData) {
        const { t } = useTranslations();
        const { providerName } = extraData;

        const downloadButton = [
            NotificationKeys.SOFTWARE_UNAVAILABLE,
            NotificationKeys.SOFTWARE_PERMISSIONS,
        ];
        const tryAgainButton = [
            NotificationKeys.GENERAL_ERROR,
            NotificationKeys.SOFTWARE_UNAVAILABLE,
            NotificationKeys.LOCATION_INVALID,
            NotificationKeys.LOCATION_OUT_OF_BOUNDS,
            NotificationKeys.SOFTWARE_PERMISSIONS,
        ];
        const ignoreButton = [
            NotificationKeys.GENERAL_ERROR,
            NotificationKeys.SOFTWARE_UNAVAILABLE,
            NotificationKeys.LOCATION_INVALID,
            NotificationKeys.LOCATION_OUT_OF_BOUNDS,
            NotificationKeys.SOFTWARE_PERMISSIONS,
        ];

        const downloadButtonCallback = this.getDownloadButtonCallback(extraData);
        const tryAgainButtonCallback = this.initTryAgain;
        const ignoreButtonCallback = this.getIgnoreButtonCallback(extraData);

        const firstLine = [
            downloadButton.includes(providerKey)
                ? this.getDownloadButtonParameters(t, downloadButtonCallback, providerName)
                : false,
        ];
        const secondLine = [
            tryAgainButton.includes(providerKey) ? this.getTryAgainButtonParameters(t, tryAgainButtonCallback) : false,
            ignoreButton.includes(providerKey) ? this.getIgnoreButtonParameters(t, ignoreButtonCallback) : false,
        ];

        return [
            firstLine.filter(Boolean),
            secondLine.filter(Boolean),
        ];
    },

    //region Callbacks
    getDownloadButtonCallback(extraData) {
        const {
            downloadUrl,
            providerName,
        } = extraData;
        let onClickHandler = () => {window.open(downloadUrl, '_blank', 'noreferrer');};

        if (providerName === GeoComplyProvidersEnum.GLV) {
            const { callbackFunctions: { downloadCB } } = extraData;
            onClickHandler = downloadCB;
        }

        return onClickHandler;
    },

    initTryAgain() {
        if (window.ng_fw) {
            // Pass data as required by documentation.
            window.ng_fw.startLocationCheck({
                reason: GeoComplyStringsEnum.LOCATION_CHECK_START_REASON,
                mode: GeoComplyStringsEnum.LOCATION_CHECK_START_MODE,
            });
        }
    },

    getIgnoreButtonCallback(extraData) {
        const {
            minimizePopup,
            providerName,
        } = extraData;
        let onClickHandler = minimizePopup;

        if (providerName === GeoComplyProvidersEnum.GLV) {
            const { callbackFunctions: { declineCB } } = extraData;
            onClickHandler = () => {
                minimizePopup();
                declineCB();
            };
        }

        return onClickHandler;
    },
    //endregion

    //region Parameters
    getDownloadButtonParameters(t, onClickHandler, providerName) {
        const desktopOnlyProviders = [
            GeoComplyProvidersEnum.PLC,
        ];

        return {
            buttonClassModifiers: [
                GeoComplyButtonModificatorsEnum.HIGHLIGHTED,
                desktopOnlyProviders.includes(providerName) ? GeoComplyButtonModificatorsEnum.DESKTOP_ONLY : false,
            ].filter(Boolean),
            onClickHandler,
            buttonText: t(TranslationKeys.DOWNLOAD_BUTTON),
        };
    },

    getTryAgainButtonParameters(t, onClickHandler) {
        return {
            buttonClassModifiers: [
                GeoComplyButtonModificatorsEnum.HYPERLINK_ON_DESKTOP,
                GeoComplyButtonModificatorsEnum.REGULAR_ON_MOBILE,
            ],
            onClickHandler,
            buttonText: t(TranslationKeys.TRY_AGAIN_BUTTON),
        };
    },

    getIgnoreButtonParameters(t, onClickHandler) {
        return {
            buttonClassModifiers: [
                GeoComplyButtonModificatorsEnum.HYPERLINK_ON_DESKTOP,
                GeoComplyButtonModificatorsEnum.REGULAR_ON_MOBILE,
            ],
            onClickHandler,
            buttonText: t(TranslationKeys.IGNORE_BUTTON),
        };
    },
    //endregion

    // endregion
};
