import { createSlice } from '@reduxjs/toolkit';
import { getVerticalDetails } from '../app/actions';

const initialState = {
    platformReady: false,
    platformGamesReady: false,
    platformId: null,
    brandName: null,
    isMobile: true,
    isNeoCmsIntegrationEnabled: false,
    featureRelatedGameCategories: {
        landing_pages_games_block: null,
        search_trending: null,
        play_next_in_game_page: null,
    },
    isLeftMenuOpened: false,
    areFavoritesEnabled: false,
    regulation: '',
    casinoId: null,
    language: {
        iso2: null,
        iso3: null,
        wpml: null,
    },
    countryCode: null,
    config: {},
    verticals: null,
    verticalsById: null,
    currentVerticalId: null,
    platformCategories: null,
    backendPageTemplate: null,
    casinoBanners: {
        placeholderBannerData: null,
        bannersList: null,
    },
    liveCasinoBanners: {
        placeholderBannerData: null,
        bannersList: null,
    },
    verticalDetails: {
        seoStrip: null,
        isSearchEnabled: false,
    },
    sportLoaderIcons: null,
    rbcSettings: {
        isHideLoyaltyPageFlag: null,
        isPromotionsAvailable: null,
    },
    header: {
        isMobileSearchOpened: false,
        globalComponentsVisibility: {
            header: {
                verticalsNav: true,
                subNav: true,
                loginButton: true,
            },
            footer: {
                quickLinks: true,
            },
        },
    },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        handlePlatformReady(state, action) {
            state.platformReady = action.payload;
        },
        handlePlatformGamesReady(state, action) {
            state.platformGamesReady = action.payload;
        },
        setBrandName(state, action) {
            state.brandName = action.payload;
        },
        fillPlatformStatus(state, action) {
            state.isMobile = action.payload.isMobile;
            state.platformId = action.payload.platformId;
        },
        setRbc(state, action) {
            state.regulation = action.payload.regulation;
            state.casinoId = parseInt(action.payload.casinoId, 10);
            state.countryCode = action.payload.countryCode;
        },
        setRbcSettings(state, action) {
            state.rbcSettings.isHideLoyaltyPageFlag = action.payload.isHideLoyaltyPageFlag;
            state.rbcSettings.isPromotionsAvailable = action.payload.isPromotionsAvailable;
            state.rbcSettings.shouldShowLastLogin = action.payload.shouldShowLastLogin;
        },
        fillLanguage(state, action) {
            state.language.iso2 = action.payload.iso2;
            state.language.iso3 = action.payload.iso3;
            state.language.wpml = action.payload.wpml;
        },
        goTo(state, action) {
            AsgApp.navigateTo(action.payload);
        },
        openInfoPopup(state, action) {
            AsgApp.openInfoPopup(action.payload, new Event('click'));
        },
        showRegistration() {
            AsgApp.showRegistration();
        },
        showDeposit() {
            AsgApp.showDeposit();
        },
        showWithdraw() {
            AsgApp.showWithdraw();
        },
        showLogin() {
            AsgApp.showLogin();
        },
        showMyAccount() {
            AsgApp.showMyAccount();
        },
        showRealityCheck() {
            AsgApp.showRealityCheck();
        },
        showChat() {
            AsgApp.showChat();
        },
        showDocumentUpload() {
            AsgApp.showDocumentUpload();
        },
        logout() {
            AsgApp.logout();
        },
        loadSport(state, action) {
            const { selector, location } = action.payload;
            SportFW.initSport(selector, { pathname: location });
        },
        fillConfig(state, action) {
            state.config = {
                ...state.config,
                ...action.payload
            };
        },
        fillVerticals(state, action) {
            state.verticals = action.payload.byOrder;
            let verticalsById = action.payload.byId;

            // verticals by ID not passed - calculate it on the fly
            if (!verticalsById) {
                verticalsById = {};
                action.payload.byOrder.forEach((vertical) => {
                    verticalsById[vertical.id] = vertical;
                });
            }

            state.verticalsById = verticalsById;
        },
        setCurrentVertical(state, action) {
            state.currentVerticalId = action.payload;
        },
        setCurrentVerticalIdCookie(state, action) {
            AsgApp.setCurrentVerticalIdCookie(action.payload.verticalId, action.payload.path);
        },
        setCurrentVerticalCategorySet(state, action) {
            const currentCategorySet = {
                categoryId: action.payload.categoryId,
                subCategoryId: action.payload.subCategoryId,
            };

            AsgApp.setCurrentCategorySet(currentCategorySet, action.payload.path);
        },
        fillPlatformCategories(state, action) {
            state.platformCategories = action.payload;
        },
        fillBackendPageTemplate(state, action) {
            state.backendPageTemplate = action.payload;
        },
        // region banners actions
        fillBannersPlaceholderData(state, action) {
            state.casinoBanners.placeholderBannerData = action.payload;
            state.liveCasinoBanners.placeholderBannerData = action.payload;
        },
        fillCasinoBanners(state, action) {
            state.casinoBanners.bannersList = action.payload;
        },
        fillLiveCasinoBanners(state, action) {
            state.liveCasinoBanners.bannersList = action.payload;
        },
        // endregion
        setIsSearchEnabledOnVertical(state, action) {
            state.verticalDetails.isSearchEnabled = action.payload;
        },
        fillSportLoaderIcons(state, action) {
            state.sportLoaderIcons = action.payload;
        },
        setIsMobileSearchOpened(state, action) {
            state.header.isMobileSearchOpened = action.payload;
        },
        setIsLeftMenuOpened(state, action) {
            state.isLeftMenuOpened = action.payload;
        },
        setAreFavoritesEnabled(state, action) {
            state.areFavoritesEnabled = action.payload;
        },
        setHeaderComponentsVisibility(state, action) {
            state.header.globalComponentsVisibility = action.payload;
        },
        renderPanicButton(state, action) {
            AsgApp?.panicButton?.showBySelector(
                action.payload.selector,
                action.payload.text,
            );
        },
        setIsNeoCmsIntegrationEnabled(state, action) {
            state.isNeoCmsIntegrationEnabled = action.payload;
        },
        fillFeatureRelatedGameCategories(state, action) {
            state.featureRelatedGameCategories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVerticalDetails.fulfilled, (state, action) => {
            if (action.payload !== null) {
                state.verticalDetails.seoStrip = action.payload.seoStrip;
                state.verticalDetails.isSearchEnabled = action.payload.isSearchEnabled;
            }
        });
    },
});
