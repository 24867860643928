import { createAsyncThunk } from '@reduxjs/toolkit';
import { PromotionsService } from '../../services/promotions.service';

export const getPromotionsList = createAsyncThunk(
    'promotions/fetchFold',
    async (requestParams) => {
        try {
            const response = await PromotionsService.getPromotionsList(requestParams);
            return response.data.promotions;
        } catch {
            return null;
        }
    }
);
