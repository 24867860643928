import { createLogger } from 'redux-logger';

export const logger = createLogger({
    duration: true,
    collapsed: true,
    colors: {
        title: (action) => (action.error ? 'firebrick' : 'deepskyblue'),
        prevState: () => '#1C5FAF',
        action: () => '#149945',
        nextState: () => '#A47104',
        error: () => '#ff0005',
    },
});

const middleware = [
    logger,
];

export { middleware };
