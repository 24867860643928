import React from 'react';
import './NotificationTitle.css';

export const NotificationTitle = ({
    title,
}) => {
    return <div className="flying-notification__title">
        {title}
    </div>;
};
