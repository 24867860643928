import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    banners: {
        active: false,
        bannersList: null,
        placeholderBannerData: null,
    }
};

export const sportSlice = createSlice({
    name: 'sport',
    initialState,
    reducers: {
        fillShowSportBanners(state, action) {
            state.banners.active = action.payload;
        },
        fillSportBanners(state, action) {
            state.banners.bannersList = action.payload;
        },
        fillSportBannersPlaceholderData(state, action) {
            state.banners.placeholderBannerData = action.payload;
        },
    },
});
