import React from 'react';
import './SvgIconMask.css';

export const SvgIconMask = ({
    className = '',
    iconUrl,
    width = '10',
    height = '10',
    bgColor = 'var(--icon-color, #787878)',
}) => {
    return <div
        className={`svg-icon-mask ${className}`}
        style={{
            width,
            height,
            backgroundColor: bgColor,
            maskImage: `url("${iconUrl}")`,
            WebkitMaskImage: `url("${iconUrl}")`,
        }}
    ></div>;
};
