import React from 'react';
import './NotificationCtaButton.css';
import { FlyingNotificationCtaTypesEnum } from '../../../../enums/flying-notification-types.enum';

export const NotificationCtaButton = ({
    cta,
    onClickHandler,
}) => {
    const {
        text,
        type = FlyingNotificationCtaTypesEnum.TEXT,
    } = typeof cta === 'string' ? { text: cta } : cta;

    if (!text) {
        return null;
    }

    const baseClass = 'flying-notification__cta-button';
    const ctaClass = [
        baseClass,
        `${baseClass}--${type}`,
    ].join(' ');

    return <div
        className={ctaClass}
        onClick={onClickHandler}
    >
        {text}
    </div>;
};
