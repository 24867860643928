import React from 'react';
import './PostLoginError.css';
import { useTranslations } from '../../../../shared/src/hooks/use-translations.hook';
import { SvgIconMask } from '../../../../shared/src/components/SvgIconMask';
import noSignalIcon from '../../../images/noSignalIcon.svg';

export const PostLoginError = () => {
    const { t } = useTranslations();

    return <div className="loyalty-post-login-error">
        <SvgIconMask
            className={'loyalty-post-login-error__icon'}
            iconUrl={noSignalIcon}
            width={132}
            height={144}
        />
        <div className="loyalty-post-login-error__title">{t('externalLoyaltySomethingWentWrong')}</div>
        <div className="loyalty-post-login-error__subtitle">{t('externalLoyaltyTryLater')}</div>
    </div>;
};
