import React, { useState } from 'react';
import './NotificationContainer.css';
import { FlyingNotificationTypesEnum } from '../../../enums/flying-notification-types.enum';
import { NotificationTitle } from './NotificationTitle';
import { NotificationMessage } from './NotificationMessage';
import { NotificationCtaButton } from './NotificationCtaButton';

export const NotificationContainer = ({
    title,
    message,
    cta,
    options = null,
}) => {
    const [isNotificationShown, setIsNotificationShown] = useState(true);
    const {
        type = FlyingNotificationTypesEnum.ISLAND,
    } = options;

    const closeNotification = () => setIsNotificationShown(false);

    if (!isNotificationShown) {
        return null;
    }

    const baseClass = 'flying-notification';
    const containerClass = [
        baseClass,
        `${baseClass}--${type}`,
    ].join(' ');

    return <div className={containerClass} >
        <div className="flying-notification__section">
            <NotificationTitle title={title} />
            <NotificationMessage message={message} />
        </div>
        <div className="flying-notification__section flying-notification__cta-section">
            <NotificationCtaButton
                cta={cta}
                onClickHandler={closeNotification}
            />
        </div>
    </div>;
};
