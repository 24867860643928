import React from 'react';
import './GeoComplyButtons.css';
import { GeoComplyButton } from '../GeoComplyButton';

export const GeoComplyButtons = ({
    buttonsData,
    extraClass = '',
}) => {
    if (buttonsData.length < 1) {
        return null;
    }

    const buttonsClass = `geo-comply__buttons-wrapper ${extraClass}`;
    const buttons = buttonsData.map((data, index) => <GeoComplyButton {...data} key={index} />);

    return <div className={buttonsClass}>
        {buttons}
    </div>;
};
