/* global webkit */
export default class IOSNativeAppInterface {
    emit(e) {
        const callbackScheme = getQueryVariable('callbackScheme');
        if (callbackScheme) {
            const safariCallbackPrefix = getQueryVariable('safariCallbackPrefix');
            const eventObj = JSON.parse(e);
            let customSchemeUrl = `${callbackScheme}://${safariCallbackPrefix}/${eventObj.eventName}?`;
            const eventData = eventObj.eventData || {};
            Object.keys(eventData).forEach((key, i) => {
                customSchemeUrl += `${key}=${eventData[key]}`;
                if (i !== Object.keys(eventData).length - 1) {
                    customSchemeUrl += '&';
                }
            });
            window.location.href = customSchemeUrl;
        } else {
            webkit.messageHandlers.notifyNativeApplication.postMessage(e);
        }
    }
}

function getQueryVariable(variable) {
    let paramValue = '';
    const query = window.location.search.substring(1);
    const queryParamPairs = query.split('&');

    queryParamPairs.every(queryParamPair => {
        const [param, value] = queryParamPair.split('=');
        if (decodeURIComponent(param) === variable) {
            paramValue = decodeURIComponent(value);
            return false;
        }
        return true;
    });

    return paramValue;
}
