import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BannerSlide } from '../BannerSlide';
import { useSelector } from 'react-redux';
import 'swiper/css/bundle';
import './BannersCarousel.css';
import '../../../styles/shimmer-animations.css';

export const BannersCarousel = ({
    isAEBFlag = false ,
    bannersData = [],
    globals,
    usePnPBanners = true,
}) => {
    const [mySwiper, setMySwiper] = useState({});
    const [autoPlayOptionEnabled, setAutoPlayOptionEnabled] = useState(true);
    const { isMobile } = useSelector((state) => state.app);
    const bannersCount = Array.isArray(bannersData) && bannersData.length ? bannersData.length : 0;

    // don't autoplay on 1 banner
    useEffect(() => {
        if (bannersCount <= 1) {
            setAutoPlayOptionEnabled(false);
        }
    }, [bannersData]);

    if (!bannersData) {
        return null;
    }

    /* Add banners slides */
    let banners = bannersData.map((bannerContent, index) => {
        const slideDelay = (index === 0 ? 8000 : 5000);

        return <SwiperSlide
            key={bannerContent.actions.banner.ActionID+index}
            virtualIndex={bannerContent.actions.banner.ActionID}
            data-swiper-autoplay={slideDelay}
        >
            <BannerSlide
                bannerContent={bannerContent}
                isAEBFlag={isAEBFlag}
                globals={globals}
                usePnPBanners={usePnPBanners}
            />
        </SwiperSlide>;
    });

    // banner options calculations
    let bannersEffect = isAEBFlag ? 'fade' : 'slide';
    // always slide for mobile
    if (isMobile) {
        bannersEffect = 'slide';
    }
    const showNavigation = bannersCount > 1;
    const loopEnabled = bannersCount > 1;

    /**
     * @link https://swiperjs.com/swiper-api
     */
    return <>
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, EffectFade, Autoplay]}
            slidesPerView={1}
            navigation={showNavigation}
            pagination={{
                type: 'bullets',
                el: '.asg-banners-custom-pagination',
            }}
            effect={bannersEffect}
            fadeEffect={{ crossFade: true }}
            speed={1000}
            loop={loopEnabled}
            autoplay={{
                enabled: autoPlayOptionEnabled,
                delay: 6000,
                // should be opposite to autoplay to prevent autoplay restart on hover
                disableOnInteraction: !autoPlayOptionEnabled,
                pauseOnMouseEnter: true,
            }}
            onInit={(ev) => {
                setMySwiper(ev);
            }}
            onReachEnd={() => {
                // disable autoplay mode
                mySwiper?.autoplay?.stop();
                setAutoPlayOptionEnabled(false);
            }}
        >
            {banners}
        </Swiper>
        <div className="asg-banners-custom-pagination" />
    </>;
};
