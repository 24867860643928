import React from 'react';
import './GeoComplyMessage.css';

export const GeoComplyMessage = ({
    message = null,
}) => {
    if (!message) {
        return null;
    }

    return <div className="geo-comply__message">
        {message}
    </div>;
};
