import { verticalTypesEnum } from '../enums/vertical-types.enum';
import { appSlice } from '../store/app/reducer';
import { SubcategoriesService } from '../../../verticals/src/services/subcategories.service';
import { AppCookieNames } from '../enums/cookies-names.enum';
import { AppService } from './app.service';

export const JackpotsService = {
    prepareJackpotsWorker: null,

    getJackpots(requestParams) {
        return new Promise((resolve, reject) => {
            SolarGatewayAPI.get(
                '/api/v1/jackpots',
                requestParams,
                (response) => {
                    resolve(response);
                },
                (e) => {
                    Tools.error('GET jackpots request failed', e);
                    reject(e);
                },
            );
        });
    },

    processJackpotsApiResponse({
        verticals,
        verticalTypesEnum,
        verticalActionTypesEnum,
        games,
        jackpots,
        jackpotsByOrder,
        jackpotsBanners,
        jackpotsCategories,
        themePublicPath,
    }) {
        if (!this.prepareJackpotsWorker) {
            this.prepareJackpotsWorker = new Worker(`${themePublicPath}/workers/prepare-jackpots.worker.js`);
        }

        return new Promise((resolve, reject) => {
            this.prepareJackpotsWorker.postMessage({
                verticals,
                verticalTypesEnum,
                verticalActionTypesEnum,
                games,
                jackpots,
                jackpotsByOrder,
                jackpotsBanners,
                jackpotsCategories,
            });

            this.prepareJackpotsWorker.onmessage = (e) => {
                resolve(e.data);
            };

            this.prepareJackpotsWorker.onerror = (e) => {
                Tools.error('processJackpotsApiResponse failed', e);
                reject(new Error('processJackpotsApiResponse failed'));
            };
        });
    },

    applyDeepLink({
        verticals,
        dispatch,
        jackpotId,
        forceUpdateVerticals,
    }) {
        const { setCurrentVertical } = appSlice.actions;

        // find JP vertical in verticals state
        const jpVertical = AppService.getVerticalByType(verticals, verticalTypesEnum.JACKPOT);

        // set selected jackpot subcategory id as currently selected subcategory
        SubcategoriesService.saveDeeplinkSubcategory(jpVertical.id, jackpotId);

        // save vertical to cookie
        const lsValue = JSON.parse(Cookie.read(AppCookieNames.CURRENT_VERTICAL_ID)) || {};
        const lsValueNew = {
            ...lsValue,
            [window.location.pathname]: jpVertical.id,
        };
        Cookie.create(AppCookieNames.CURRENT_VERTICAL_ID, JSON.stringify(lsValueNew), '180', '/');

        // set JP verticals as current vertical
        dispatch(setCurrentVertical(String(jpVertical.id)));
        forceUpdateVerticals();

        // scroll subcategory into view
        SubcategoriesService.scrollIntoView({
            force: true,
        });
    },
};
