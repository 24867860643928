import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StringUtilService } from '../../../services/util/string-util.service';
import { ArrayUtilService } from '../../../services/util/array-util.service';

const JackpotBasic = ({ id, currency, currencySign }) => {
    const [jackpotAmount, setJackpotAmount] = useState(0);
    const { jackpots, gamesToJackpots } = useSelector((state) => state.jackpots);
    const decodeCurrency = StringUtilService.decodeHtmlEntity(currencySign);

    useEffect(() => {
        const jackpotId = gamesToJackpots && Object.keys(gamesToJackpots).length > 0 &&
            gamesToJackpots[id] && ArrayUtilService.getRandomElements(gamesToJackpots[id])[0];
        if (jackpotId) {
            // en-US format = 9,999,999
            const jackpotValue = Number(jackpots[jackpotId]?.amount[currency].toFixed()).toLocaleString('en-US');
            setJackpotAmount(jackpotValue);
        }
    }, [gamesToJackpots]);

    if (!jackpotAmount) {
        return null;
    }

    return (
        <div className='casino-thumbnail__jackpot-amount'>
            {`${decodeCurrency}${jackpotAmount}`}
        </div>
    );
};

export default JackpotBasic;
