import React, { useRef } from 'react';
import { useFitTextToRows } from '../../../hooks/use-fit-text-to-rows.hook';

export const BannerCtaButton = ({
    ctaClickHandler,
    ctaText
}) => {
    const ctaButtonRef = useRef();

    useFitTextToRows(
        ctaButtonRef,
        1,
        [ctaButtonRef],
    );

    return (
        <div
            className="carousel-content__cta-button"
            onClick={ctaClickHandler}
            ref={ctaButtonRef}
        >
            {ctaText}
        </div>
    );
};
