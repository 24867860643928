import React from 'react';
import './PromotionStrip.css';
import { CountdownStrip } from './CountdownStrip';
import { LiveStrip } from './LiveStrip';
import { NoCountdownModeStrip } from './NoCountdownModeStrip';
import { ExpiredStrip } from './ExpiredStrip';

export const PromotionStrip = ({
    countdownUntil,
    countdownTitle,
    ctaAction,
    ctaActionValue,
    promotionButtonText,
    isLiveMode,
    isEndMode,
    noCountdownMode,
    promotionTitle,
    promotionSource,
}) =>
    <>
        {countdownUntil && <CountdownStrip
            countdownTitle={countdownTitle}
            countdownUntil={countdownUntil}
            ctaAction={ctaAction}
            ctaActionValue={ctaActionValue}
            promotionButtonText={promotionButtonText}
        />}

        {isLiveMode && <LiveStrip
            ctaAction={ctaAction}
            ctaActionValue={ctaActionValue}
            promotionButtonText={promotionButtonText}
        />}

        {isEndMode && <ExpiredStrip/>}

        {noCountdownMode && <NoCountdownModeStrip
            ctaAction={ctaAction}
            ctaActionValue={ctaActionValue}
            promotionButtonText={promotionButtonText}
            promotionTitle={promotionTitle}
            promotionSource={promotionSource}
        />}
    </>;
