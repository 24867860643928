import React from 'react';
import './GeoComplyMinimized.css';
import mapPinIcon from '../../../../images/gc-popup-icon.svg';
import maximizeIcon from '../../../../images/maximize-gc-popup.svg';

export const GeoComplyMinimized = ({
    title,
    maximizePopupHandler,
}) => {
    return <div className="geo-comply-minimized">
        <img
            className="geo-comply-minimized__icon geo-comply-minimized__icon--logo"
            src={mapPinIcon}
            alt=""
        />
        <div className="geo-comply-minimized__title">{title}</div>
        {maximizePopupHandler && <img
            className="geo-comply-minimized__icon geo-comply-minimized__icon--maximize"
            src={maximizeIcon}
            alt=""
            onClick={maximizePopupHandler}
        />}
    </div>;
};
