import { verticalActionTypesEnum } from '../enums/vertical-types.enum';
import { PamWebapiService } from './pam-webapi.service';

export const FavoritesService = {
    async getPlayerFavoriteGames () {
        const apiEndpoint = '/Player/GetPlayerFavoriteGames';
        const apiData = {
            SessionToken: AsgFW.getSessionDetails().SessionToken,
            PlayerID: AsgFW.getSessionDetails().EncryptedUserID,
        };

        return PamWebapiService.postRequest(apiEndpoint, apiData);
    },

    async setPlayerFavoriteGames(gameIds) {
        const apiEndpoint = '/Player/SetPlayerFavoriteGames';
        const games = gameIds.map(Id => {return { Id };});
        const apiData = {
            SessionToken: AsgFW.getSessionDetails().SessionToken,
            PlayerID: AsgFW.getSessionDetails().EncryptedUserID,
            Games: games,
        };

        return PamWebapiService.postRequest(apiEndpoint, apiData);
    },

    /**
     * @param {object} gamesData categories with games from api response
     * @param {[]} categoriesOrder array of category names
     */
    addFavoritesCategory(gamesData, categoriesOrder) {
        if (categoriesOrder.includes(verticalActionTypesEnum.FAVORITES_CATEGORY)) {
            const category = {
                category: {
                    name: verticalActionTypesEnum.FAVORITES_CATEGORY,
                    rows: 1,
                },
                games: [],
            };
            // Add 2 categories for sliders with different number of rows
            gamesData.push(category);
            gamesData.push({
                ...category,
                category: {
                    ...category.category,
                    rows: 2,
                },
            });
        }
    },

    /**
     * @param {object} gamesData categories with games from api response
     * @param {[]} categoriesOrder array of category names
     */
    getFilteredCategories(gamesData, categoriesOrder) {
        return gamesData.filter(item => categoriesOrder.includes(item.category.name));
    },

    /**
     * @param {object} gamesData categories with games from api response
     * @param {[]} categoriesOrder array of category names
     */
    sortCategories(gamesData, categoriesOrder) {
        gamesData.sort((a, b) => categoriesOrder.indexOf(a.category.name) - categoriesOrder.indexOf(b.category.name));
    },
};
