export const RecommendationsService = {
    getRecommendedMostPlayedGames(requestParams) {
        return new Promise((resolve, reject) => {
            SolarGatewayAPI.get(
                '/api/v1/recommendations/most-played-games',
                requestParams,
                (response) => {
                    resolve(response);
                },
                (e) => {
                    Tools.error('Recommendations most played games request failed', e);
                    reject(e);
                },
            );
        });
    },

    getRecommendedGames(requestParams) {
        return new Promise((resolve, reject) => {
            SolarGatewayAPI.get(
                '/api/v1/recommendations/games',
                requestParams,
                (response) => {
                    resolve(response);
                },
                (e) => {
                    Tools.error('Recommendations games request failed', e);
                    reject(e);
                },
            );
        });
    },


};
