import { RequestStatusesEnum } from '../../../shared/src/enums/request-statuses.enum';
import { LoyaltyPlanApiCallActionsEnum } from '../enums/loyalty-plans.enum';

export const GeneralLoyaltyDataService = {
    getFromGeneralData(key, fallbackValue = '') {
        const value = this.getGeneralLoyaltyData()?.[key];
        return value ? value : fallbackValue;
    },

    getGeneralLoyaltyData() {
        return window?.generalLoyaltyData;
    },

    isDefaultPlanUsed() {
        return this.getLoyaltyPlan() === 'default';
    },

    getLoyaltyPlan() {
        return this.getFromGeneralData('loyaltyPlan', 'default');
    },

    getDateLocale() {
        return this.getFromGeneralData('dateLocale');
    },

    getDefaultIcon() {
        return this.getFromGeneralData('defaultIcon');
    },

    getPointsTerminology() {
        return this.getFromGeneralData('pointsTerm');
    },

    getPlanDetails() {
        return this.getFromGeneralData('planDetails', []);
    },

    getLevelData(level) {
        const planDetails = this.getPlanDetails();
        return planDetails?.[level] ? planDetails[level] : {};
    },

    getLevelName(level) {
        const currentLevelData = this.getLevelData(level);
        return currentLevelData?.description ? currentLevelData.description : '';
    },

    getLevelCardImage(level) {
        const currentLevelData = this.getLevelData(level);
        return currentLevelData?.images?.card ? currentLevelData.images.card : '';
    },

    getLevelGradientColorStart(level) {
        const currentLevelData = this.getLevelData(level);
        return currentLevelData?.colors?.gradientStart ? currentLevelData.colors.gradientStart : '';
    },

    getLevelGradientColorEnd(level) {
        const currentLevelData = this.getLevelData(level);
        return currentLevelData?.colors?.gradientEnd ? currentLevelData.colors.gradientEnd : '';
    },

    // Format date from timestamp as "d M Y" (e.g., "31 Dec 2000")
    formatDate(timestamp, locale = 'en') {
        const dateObject = new Date(timestamp);

        if (isNaN(dateObject)) {
            return '';
        }

        const day = dateObject.toLocaleDateString(locale, { day: '2-digit' });
        const month = dateObject.toLocaleDateString(locale, { month: 'short' });
        const year = dateObject.toLocaleDateString(locale, { year: 'numeric' });

        return `${day} ${month} ${year}`;
    },

    /**
     * Tries to retrieve data from the store.
     *
     * If data not fetched (either succeed or failed) - subscribes to data received event
     * and triggering an API call. Calls itself recursively from event handler.
     * Otherwise, calls callback with data or null if succeed or failed respectively.
     * @param {string} planCode
     */
    async getApiDataFromStore(planCode) {
        const { loyalty: loyaltyState } = window.AsgSharedComponents.getState();
        const status = loyaltyState?.[planCode]?.status;
        let storedData = null;

        if (status === RequestStatusesEnum.SUCCEDED) {
            storedData = loyaltyState?.[planCode]?.originalData;
        } else if (status === RequestStatusesEnum.DEFAULT) {
            const dispatch = window.AsgSharedComponents.getDispatch();
            const action = LoyaltyPlanApiCallActionsEnum[planCode];
            const result = await dispatch(action()).unwrap();
            storedData = result?.data?.original;
        } else if (status === RequestStatusesEnum.WAITING) {
            const { store } = window.AsgSharedComponents.state;
            let unsubscribe = null;
            storedData = await new Promise(resolve => {
                unsubscribe = store.subscribe(() => {
                    const { loyalty: loyaltyState } = window.AsgSharedComponents.getState();
                    const status = loyaltyState?.[planCode]?.status;
                    if (status !== RequestStatusesEnum.WAITING) {
                        resolve(loyaltyState?.[planCode]?.originalData);
                    }
                });
            });
            unsubscribe();
        }

        return storedData;
    },
};
