import React from 'react';
import './GeoComplyPopup.css';
import mapPinIcon from '../../../../images/gc-popup-icon.svg';
import { GeoComplyHeader } from '../GeoComplyHeader';
import { GeoComplyMessage } from '../GeoComplyMessage';
import { GeoComplyButtons } from '../GeoComplyButtons';

export const GeoComplyPopup = ({
    title,
    message,
    buttonsData,
}) => {
    const buttonLineClass = 'geo-comply-popup__buttons-line';
    const buttonLines = buttonsData.map((data, index) =>
        <GeoComplyButtons buttonsData={data} extraClass={buttonLineClass} key={index} />
    );
    const atLeastOneButtonsLine = buttonLines.length > 0;

    return <div className="geo-comply-popup__wrapper">
        <div className="geo-comply-popup__body">
            <div className="geo-comply-popup__icon">
                <img src={mapPinIcon} alt="" />
            </div>

            <GeoComplyHeader title={title} />
            <GeoComplyMessage message={message} />

            {atLeastOneButtonsLine &&
                (<div className="geo-comply-popup__buttons-block">
                    {buttonLines}
                </div>)}
        </div>
    </div>;
};
