export const SubcategoriesService = {
    savedSubcategoryCacheKey: 'asg-selected-subcategory-id',
    savedSubcategoryDeepLinkCacheKey: 'asg-selected-subcategory-id-deep-link',

    saveSelectedSubcategory(categoryId, subcategoryId) {
        localStorage.setItem(`${this.savedSubcategoryCacheKey}-${categoryId}`, subcategoryId);
    },

    getSelectedSubcategory(categoryId) {
        return localStorage.getItem(`${this.savedSubcategoryCacheKey}-${categoryId}`);
    },

    saveDeeplinkSubcategory(categoryId, subcategoryId) {
        localStorage.setItem(`${this.savedSubcategoryDeepLinkCacheKey}-${categoryId}`, subcategoryId);
    },

    getDeeplinkSubcategory(categoryId) {
        return localStorage.getItem(`${this.savedSubcategoryDeepLinkCacheKey}-${categoryId}`);
    },

    clearDeeplinkSubcategory(categoryId) {
        return localStorage.removeItem(`${this.savedSubcategoryDeepLinkCacheKey}-${categoryId}`);
    },

    /**
     * Sums heights of all elements above games grid
     * The value is used for scroll up to grid start on subcategory change
     */
    getSubcategoriesStickyPosition() {
        let subCategoriesStickyPosition;
        let bannersHeight = 0;

        const bannersElement = document.getElementById('asg-vertical-banners-portal');
        if (bannersElement) {
            const { height: bannersHeightCalculated } = bannersElement.getBoundingClientRect();
            bannersHeight = bannersHeightCalculated;
        }

        if (AsgFW.isMobilePamPlatform()) {
            const { height: subheaderHeight } = AsgApp.getIsOneOfNativeApps()
                ? { height: 0 }
                : document.getElementsByClassName('subheader-mobile')[0].getBoundingClientRect();
            const { height: winnersHeight } = document.getElementById('asg-vertical-mobile-winners-portal')
                .getBoundingClientRect();
            subCategoriesStickyPosition = Math.floor(
                bannersHeight + subheaderHeight + winnersHeight,
            );
        } else {
            const { height: subheaderHeight } = document.getElementsByClassName('subheader-desktop')[0]
                .getBoundingClientRect();
            subCategoriesStickyPosition = bannersHeight + subheaderHeight;
        }

        return subCategoriesStickyPosition;
    },

    /**
     * Options:
     *  - force - if true will scroll always even from top of screen
     */
    scrollIntoView(options = {}) {
        const force = options.force ?? false;
        const subCategoriesStickyPosition = this.getSubcategoriesStickyPosition();

        // scroll to top of games grid
        if (force || (!force && window.scrollY > subCategoriesStickyPosition)) {
            window.scrollTo({
                top: subCategoriesStickyPosition,
                behavior: 'smooth'
            });
        }
    },
};
