import { createAsyncThunk } from '@reduxjs/toolkit';
import { VerticalDetailsService } from '../../services/vertical-details.service';

export const getVerticalDetails = createAsyncThunk(
    'verticalDetails/fetch',
    async (props) => {
        const { ajaxUri, language, verticalAltLocation, verticalType } = props.requestData;
        try {
            return VerticalDetailsService.getCachedVerticalDetailsResponse(
                ajaxUri,
                language,
                verticalAltLocation,
                verticalType,
            );
        } catch (error) {
            props.globals.Tools.error('verticalDetails/fetch error', error);
            return null;
        }
    }
);
