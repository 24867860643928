import React, { useState, useRef, useEffect } from 'react';
import './PnPDepositWidget.css';
import { bannersService } from '../../../services/banners-service';

export const PnPDepositWidget = ({
    className = '',
    changeAmountHandler,
    depositHandler,
    globals: {
        Dictionary,
        AsgFW,
    },
}) => {
    const currency = AsgFW.getCurrency();
    const pnpData = AsgFW.getPayNPlayData();

    const [isShowOtherInput, setIsShowOtherInput] = useState(false);
    const [isOtherInputHighlighted, setIsOtherInputHighlighted] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [depositVariants, setDepositVariants] = useState([]);
    const userInputRef = useRef(null);

    // convert deposit amounts from number[] to internal data structure
    useEffect(() => {
        if (Array.isArray(pnpData.predefinedAmounts)) {
            setDepositVariants(pnpData.predefinedAmounts.map((amount) => ({
                amount,
                amountFormatted: bannersService.addCurrencySymbol(amount, currency),
                active: false,
            })));
        }
    }, []);

    const inputOnBlurHandler = () => {
        let userInputAmount = parseInt(userInput, 10);

        if (!isNaN(userInputAmount)) {
            // keep value in min/max range
            userInputAmount = userInputAmount > pnpData.maxDepositAmount
                ? pnpData.maxDepositAmount : userInputAmount;
            userInputAmount = userInputAmount < pnpData.minDepositAmount
                ? pnpData.minDepositAmount : userInputAmount;


            setUserInput(bannersService.addCurrencySymbol(userInputAmount, currency));
            changeAmountHandler(userInputAmount);
            setIsOtherInputHighlighted(true);
            return;
        }

        hideOtherInput();
    };

    const inputRemoveNonNumericHandler = (e) => {
        deactivateAllDepositVariants();
        setUserInput(e.target.value.replace(/\D/g, ''));
    };

    const selectPredefinedDepositVariant = (depositVariantIndex) => {
        hideOtherInput();
        const selectedDepositVariant = depositVariants[depositVariantIndex];
        changeAmountHandler(selectedDepositVariant.amount);
        activateDepositVariant(depositVariantIndex);
    };

    const hideOtherInput = () => {
        setIsShowOtherInput(false);
        setIsOtherInputHighlighted(false);
    };

    const getDeactivatedDepositVariants = () => (depositVariants.map(depositVariant => {
        depositVariant.active = false;
        return depositVariant;
    }));

    const deactivateAllDepositVariants = () => {
        setDepositVariants(getDeactivatedDepositVariants());
    };

    const activateDepositVariant = (depositVariantIndex) => {
        const depositVariantUpdated = getDeactivatedDepositVariants();

        depositVariantUpdated[depositVariantIndex].active = true;

        setDepositVariants(depositVariantUpdated);
    };

    const showUserInput = () => {
        deactivateAllDepositVariants();
        setIsShowOtherInput(true);
    };

    useEffect(() => {
        // TODO: find out why only 2nd focus call works
        if (isShowOtherInput && userInputRef.current) {
            userInputRef.current.focus();
            userInputRef.current.focus();
        }
    }, [isShowOtherInput]);

    const userInputClasses = [
        'asg-carousel-pnp__other',
        isShowOtherInput ? '' : 'asg-carousel-pnp__btn--hidden',
        isOtherInputHighlighted ? 'asg-carousel-pnp__btn--selected' : '',
    ];

    const otherButtonClasses = [
        'asg-carousel-pnp__other',
        isShowOtherInput ? 'asg-carousel-pnp__btn--hidden' : '',
    ];

    return (
        <div className={`asg-carousel-pnp ${className}`}>
            <div className="asg-carousel-pnp__deposit-variants">
                {depositVariants.map(({ amount, active, amountFormatted, }, key) => {
                    const depositVariantClasses = [
                        'asg-carousel-pnp__btn',
                        active ? 'asg-carousel-pnp__btn--selected' : '',
                    ];

                    return <div
                        className={depositVariantClasses.join(' ')}
                        key={key}
                        onClick={() => {selectPredefinedDepositVariant(key);}}
                    >{active ? amountFormatted : amount}</div>;
                })}

                <div className={otherButtonClasses.join(' ')} onClick={showUserInput}>
                    {Dictionary.translate('other')}
                </div>

                <input
                    className={userInputClasses.join(' ')}
                    onFocus={inputRemoveNonNumericHandler}
                    onChange={inputRemoveNonNumericHandler}
                    onBlur={inputOnBlurHandler}
                    value={userInput}
                    type="text"
                    ref={userInputRef}
                />
            </div>

            <div className="asg-carousel-pnp__deposit-cta">
                <div
                    className="asg-carousel-pnp__deposit-button"
                    onClick={depositHandler}
                >{Dictionary.translate('depositNplay')}</div>

                <div className="asg-carousel-pnp__powered-by">
                    {Dictionary.translate('poweredBy')}
                    <div className="asg-carousel-pnp__powered-by-logo"></div>
                </div>
            </div>
        </div>
    );
};
