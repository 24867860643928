export const bannersActionTypesEnum = {
    WIDGET: 1,
    LINK: 2,
    GAME: 3,
    DEEP_LINK: 4,
};

export const bannersActionIdsEnum = {
    REGISTRATION: 'Registration',
    DEPOSIT: 'Deposit',
    LOGIN: 'Login',
    CHAT: 'Chat',
    INFO_PAGE_TERMS: 'InfoPageTerms',
};
