import { createSlice } from '@reduxjs/toolkit';
import { PlayerService } from '../../services/player.service';

const initialState = {
    session: null,
    isLoggedIn: false,
    lastLoginTimeStamp: null,
    isRegistered: false,
    recentGames: [],
    balance: {
        Currency: '',
        CurrencySign: '',
    },
    loyalty: null,
};

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setPlayerSession(state, action) {
            state.session = action.payload;
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        setLastLoginTimeStamp(state, action) {
            state.lastLoginTimeStamp = action.payload;
        },
        setIsRegistered(state, action) {
            state.isRegistered = action.payload;
        },
        setRecentGames(state, action) {
            state.recentGames = action.payload;
        },
        setBalance(state, action) {
            state.balance = action.payload;
        },
        setLoyalty(state, action) {
            state.loyalty = action.payload;
        },
        showLoginOrRegistration(state, action) {
            PlayerService.showLoginOrRegistration(action.payload ?? {});
        }
    },
});
